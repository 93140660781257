import React, { useState, createContext } from "react";
import { ToastContainer } from 'react-toastify';
import { Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import Forgotpwd from "./Components/Forgotpwd";
import Register from "./Components/Register";
import Otp from "./Components/Otp";
import Dashboardinfo from "./Components/dashboard_pages/Dashboard";
import Logout from "./Components/Logout";
import Chiller from "./Components/project/Chiller";
import Projectinformation from "./Components/project/Projectinformation";
import Payments from "./Components/project/Payments";
import Thermalstore from "./Components/project/Thermalstore";
import OperationalPhase from "./Components/project/Operational_phase";
import Resultasbuilt from "./Components/project/Resultasbuilt";
import Auxiliaries from "./Components/project/Auxiliaries";
import Fans from "./Components/project/Fans";
import CoEfficients from "./Components/project/Coefficients";
import ResultasOperated from "./Components/project/Result_operational";
import './App.css'
import ImageUpload from "./Components/Image_upload";
import DEOperationalPhase from "./Components/project/DEOperational_phase";
import 'react-toastify/dist/ReactToastify.css';
import Feedback from "./Components/dashboard_pages/Feedback";
import CmsPages from "./Components/dashboard_pages/cms_pages";
import Userprofile from "./Components/dashboard_pages/Userprofile";
import Packages from "./Components/dashboard_pages/Packages";
import Helpsupport from "./Components/dashboard_pages/Helpsupport";
import Totalprojects from "./Components/dashboard_pages/Totalprojects";
import Termsandconditions from "./Components/dashboard_pages/Termsandconditions";
import Privacypolicy from "./Components/dashboard_pages/Privacypolicy";
import LandingPage from "./Components/project/landingpage/landingPage";
import CoolingTower from "./Components/project/coolingtower";
import Pumps from "./Components/project/Pumps";
import AllResultsDisplayPage from "./Components/project/AllResultsDisplayPage";
import Pagenotfound from "./Pagenotfound";
import Authenticate from "./Authenticate";
import ViewProjectDetails from "./Components/dashboard_pages/ViewProjectDetails";
import VisulizationLandingPage from "./Components/visualization/VisulizationLandingPage";
import VisualisationLanding from "./Components/visualization/VisualLandingPage";
import { BuyPackage } from "./Components/payments/buypackage";
import { PaymentSuccessPage } from "./Components/payments/payment_success";
import PaymentsTransactions from "./Components/dashboard_pages/PaymentsTransactions";
import UserManual from "./Components/dashboard_pages/usermanual";
import { Map } from "./Components/maps/Map";
import PackagesDesign from "./Components/dashboard_pages/packagedesign";
import Error from "./Components/project/error";
export const CondenserContext = createContext();
        function App() {
        const routes = [
        { path: "/", element:  <Login /> },
        { path: "/login", element: <Login /> },
        { path: "/forgotpwd", element: <Forgotpwd /> },
        { path: "/register", element: <Register /> },
        { path: "/visuallanding", element: <VisualisationLanding /> },
        { path: "/otp", element: <Otp /> },
        { path: "/dashboard", element: <Authenticate><Dashboardinfo /></Authenticate> },
        { path: "/logout", element: <Authenticate><Logout /></Authenticate> },
        { path: "/packages", element: <Authenticate><Packages /></Authenticate> },
        { path: "/package", element: <Authenticate><PackagesDesign /></Authenticate> },
        { path: "/termsandconditions", element: <Authenticate><Termsandconditions /></Authenticate> },
        { path: "/privacypolicy", element: <Authenticate><Privacypolicy /></Authenticate> },
        { path: "/upload-image", element: <Authenticate><ImageUpload /></Authenticate> },
        { path: "/cms-pages", element: <Authenticate><CmsPages /></Authenticate> },
        { path: "/userprofile", element: <Authenticate><Userprofile /></Authenticate> },
        { path: "/packages", element: <Authenticate><Packages /></Authenticate> },
        { path: "/support", element: <Authenticate><Helpsupport /></Authenticate> },
        { path: "/feedback", element: <Authenticate><Feedback /></Authenticate> },
        { path: "/totalprojects", element: <Authenticate><Totalprojects /></Authenticate> },
        { path: "/payments", element: <Authenticate><Payments /></Authenticate> },
        { path: "/error", element: <Authenticate><Error /></Authenticate> },
        { path: "/projectinformation", element: <Authenticate><Projectinformation /></Authenticate> },
        { path: "/chiller", element: <Authenticate><Chiller /></Authenticate> },
        { path: "/thermalstore", element: <Authenticate><Thermalstore /></Authenticate> },
        { path: "/pumps", element: <Authenticate><Pumps /></Authenticate> },
        { path: "/auxiliaries", element: <Authenticate><Auxiliaries /></Authenticate> },
        { path: "/fans", element: <Authenticate><Fans /></Authenticate> },
        { path: "/cooling-towers", element: <Authenticate><CoolingTower /></Authenticate> },
        { path: "/co-efficients", element: <Authenticate><CoEfficients /></Authenticate> },
        { path: "/operational", element: <Authenticate><OperationalPhase /></Authenticate> },
        { path: "/details", element: <Authenticate><ViewProjectDetails /></Authenticate> },
        { path: "/visualisation-landing-page", element: <Authenticate><VisulizationLandingPage /></Authenticate> },
        { path: "/user-manual", element: <Authenticate><UserManual /></Authenticate> },
        { path: "/operationalboth", element: <Authenticate><DEOperationalPhase /></Authenticate> },
        { path: "/as-operated-result", element: <Authenticate><ResultasOperated /></Authenticate> },
        { path: "/resultasbuilt", element: <Authenticate><Resultasbuilt /></Authenticate> },
        { path: "/result-as-built", element: <Authenticate><Resultasbuilt /></Authenticate> },
        { path: "/final-result", element: <Authenticate><AllResultsDisplayPage /></Authenticate> },
        { path: "/buypackage", element: <Authenticate><BuyPackage /></Authenticate> },
        { path: "/payment_success", element: <Authenticate><PaymentSuccessPage /></Authenticate> },
        { path: "/payments_transactions", element: <Authenticate><PaymentsTransactions /></Authenticate> },
        { path: "/maps", element: <Authenticate><Map /></Authenticate> },
        { path: "*", element:<Pagenotfound/> }

        ];
                const [isCondenserTableDisabled, setIsCondenserTableDisabled] = useState(false);
                return (
                        <>
<CondenserContext.Provider value={{ isCondenserTableDisabled, setIsCondenserTableDisabled }}>
    <Routes>
        {routes.map((route) => (
                        <Route key={route.path} exact path={route.path} element={route.element} />
                        ))}

    </Routes>
    <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
</CondenserContext.Provider>

</>
);
}


export default App;
