import React, { useState } from "react";
 const VisualForm = (props)=> {

  const {formData,setFormData,onClickFun} = props


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

 

  return (
    <>
       {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
      <div
        className="card scrollable"
        style={{
          width: "100%",
          height: "650px",
          padding: "14px",
          overflowY: "scroll",
          backgroundImage:
            "linear-gradient(to left, rgb(0 12 46), rgb(0 32 109))",
          border: "1px solid #faf9f903",
          borderRadius: "10px 0px 0px 10px",
        }}
      >
        <form method="post" onSubmit={onClickFun} className="" action="">
          <h3 className="mb-3 text-light">Visualisation</h3>
          <div
            className="py-3"
            style={{
              backgroundColor: "rgb(29, 69, 159, 1)",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            id="visualise-wrapper"
          >
            <p className=" text-light">Project Phases</p >
            <div className="form-check" style={{ background: "#68b90b",borderRadius: "62px",padding: "11px 40px",color: "#fff"}}>
              <input type="checkbox"   onChange={handleCheck}  className="form-check-input" id="check1" name="asbuilt" value={formData.asbuilt}  />
              <label style={{color:"#fff"}} className="form-check-label" for="check1">Design Phase</label>
            </div>
            <div className="form-check" style={{ background: "#68b90b",borderRadius: "62px",padding: "11px 40px",color: "#fff",margin:"10px 0px"}}>
              <input type="checkbox"   onChange={handleCheck}  className="form-check-input" id="check2"  name="asoperated"  value={formData.asoperated} />
              <label  style={{color:"#fff"}}  className="form-check-label" for="check2">Operated Phase</label>
            </div>
            <div className="form-check" style={{ background: "#68b90b",borderRadius: "62px",padding: "11px 40px",color: "#fff"}}>
              <input type="checkbox"   onChange={handleCheck}  className="form-check-input" id="check3"  name="asbuilt_and_asoperated" value={formData.asbuilt_and_asoperated} />
              <label   style={{color:"#fff"}} className="form-check-label">Design & Operated Phases</label>
            </div>
            
          </div>

          <h3 className="my-3 text-light">Filters</h3>

          <div className="row">
            <div className="col-xl-12">
              <div className="form-group visual-group">
                <label htmlFor="owner-id">Project ID</label>
                <input
                  placeholder="Project ID"
                  type="text"
                  value={formData.project_id}
                  onChange={handleChange}
                  name="project_id"
                  className="form-control"
                  id="floatingInput"
                />
              </div>
            </div>
            <div className="col-xl-12">
              <div className="form-group visual-group">
                <label htmlFor="owner-id">Project Name </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Project Name "
                  value={formData.project_name}
                  onChange={handleChange}
                  name="project_name"
                  id="floatingInput"
                />
              </div>
            </div>
          </div>

          <hr />
          <button className="btn btn-primary" type="submit">
            Filter
          </button>
        </form>
      </div>
    </>
  );
}

export default VisualForm;