import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { feedbackAddUrl, feedbacksUrl } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";

const Feedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    access_token: localStorage.getItem("access_token"),
  });

  const [formDataErrors, setFormDataErrors] = useState({
    title: "",
    description: "",
  });

  const [allRequests, setAllRequests] = useState([]);

  const { access_token } = formData;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  //   useEffect(() => {
  //     if (dataFetchedRef.current) return;
  //     dataFetchedRef.current = true;
  //     handleSubmit()
  // }, []);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    fetchRequests();
  }, []);

  const fetchRequests = async (event) => {
    try {
      const response = await axios.post(
        feedbacksUrl,
        JSON.stringify({ access_token: access_token }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("response", response);
      setIsLoading(false);
      if (response?.data?.status === "valid") {
        // console.log("response", response.data.data);
        // setFormData(response.data.data)
        setAllRequests(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
      // alert("An error occurred while submitting the form.");
    }
  };

  const handleSubmit = async (e) => {
    // alert("PC")
    e.preventDefault();
    setIsLoading(true);
    const errors = {};
    if (!formData.title) {
      errors.title = "Please enter title";
    }
    if (!formData.description) {
      errors.description = "Please enter description";
    }

    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      setIsLoading(false);
      return;
    }
    // service call here
    try {
      const response = await axios.post(
        feedbackAddUrl,
        JSON.stringify({
          access_token: formData.access_token,
          title: formData.title,
          description: formData.description,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);
      if (response.data["status"] === "invalid") {
        // password change failed
        toast.error(response?.data?.message);
        // alert(response.data["message"]);
      } else {
        fetchRequests();
        setFormData({
          title: "",
          description: "",
          access_token: localStorage.getItem("access_token"),
        });
        // alert(response.data["message"]);
        toast.success(response?.data?.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
      // alert("Passwords not matching")
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Userdashsidebar currentPage={"feedback"} />
          <div className="content-page mt-0 class-back-ground border-colo-top">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="page-title-box">
                              <h4 className="page-title">Feedback</h4>
                            </div>
                          </div>
                        </div>
                        <form action="" method="post" onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="form-floating mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="floatingInput"
                                      placeholder="Ex.1144"
                                      value={formData.title}
                                      name="title"
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="floatingInput">Title</label>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="form-floating mb-2">
                                    <textarea
                                      className="form-control"
                                      id="floatingInput1"
                                      placeholder=""
                                      rows="6"
                                      value={formData.description}
                                      name="description"
                                      onChange={handleChange}
                                    ></textarea>
                                    <label
                                      htmlFor="floatingInput1"
                                      style={{ fontSize: "15px", opacity: "1" }}
                                    >
                                      Description
                                    </label>
                                  </div>
                                </div>
                                <div className="form-group text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-primary mr-1 waves-effect waves-light ml-2"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row profilepg">
                              <div className="col-lg-12">
                                <div id="resptable">
                                  <table className="table table-colorchild table-blk table-bacg-color">
                                    <thead>
                                      <tr className="bg-success">
                                        <th>S.no</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Created At</th>
                                        {/* <th>Remarks</th>
                                                                    <th>Status</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* //allRequests */}
                                      {allRequests &&
                                        allRequests.map((app) => {
                                          const createdAtDate = new Date(
                                            app.created_at * 1000
                                          );

                                          const day = createdAtDate
                                            .getDate()
                                            .toString()
                                            .padStart(2, "0");
                                          const month = (
                                            createdAtDate.getMonth() + 1
                                          )
                                            .toString()
                                            .padStart(2, "0"); 
                                          const year = createdAtDate
                                            .getFullYear()
                                            .toString()
                                            // .slice(-2); 

                                          const formattedCreatedAt = `${day}-${month}-${year}`;
                                          return (
                                            <>
                                              <tr
                                                key={allRequests.indexOf(app)}
                                              >
                                                <td data-title="S.No.">
                                                  {allRequests.indexOf(app) + 1}
                                                </td>
                                                <td data-title="Title">
                                                  {app.title}
                                                </td>
                                                <td data-title="Description">
                                                  {app.description}
                                                </td>
                                                <td data-title="Created At">
                                                  {formattedCreatedAt || "-"}
                                                </td>
                                                {/* <td>{app.remarks || "-"}</td>
                                                                    <td>{app.created_at || "-"}</td>
                                                                    <td><span className="badge badge-warning">Pending</span></td> */}
                                              </tr>
                                            </>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Feedback;
