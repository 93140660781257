import React, { useState, useEffect, useRef } from "react";
import '../css/userdash-app.css'
import { toast } from "react-toastify";
import '../css/userdash-newstyles.css'
import UseServices from "../services/api";
import { BaseURL, userProfileURL } from "../globalURL";
import { useCookies, withCookies } from 'react-cookie';
import { Link, useNavigate } from "react-router-dom";
import { CancelRounded, MenuRounded } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

const Userdashsidebar = (props) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const navigate = useNavigate()
    const [cookies, setCookie] = useCookies();
    const [userDetails, setuserDetails] = useState({
        access_token: localStorage.getItem("access_token"),
    });
    const dataFetchedRef = useRef(false);
    
    const handleLogout = async () => {
        // console.log(cookies)
        localStorage.setItem('access_token', "");
        localStorage.setItem('isLogin', false);
        setCookie("username", "", { path: '/' });
        setCookie("password", "", { path: '/' });
        navigate('/login')
    };

    const getUserProfileDetails = () => {
        UseServices.fetchPostData(userProfileURL, {
            access_token: userDetails.access_token,
        }) 
            .then((response) => {
                // setIsLoading(false);
                // console.log("result : ").
                // console.log(response.data)
                // set user details based on response data
                if (response.data) {
                    setuserDetails(response.data);
                } else {
                    toast.error("User not authorized");
                    // alert("TEST")
                    localStorage.clear();
                    navigate("/login");
                }
            })
            .catch((error) => {
                // setIsLoading(false);
                toast.error("Something error occured..!, Please try again.");
                console.error(error);
            });
    };




    // function openNav() {
    //     setIsNavOpen(true);
    // }

    // function closeNav() {
    //     setIsNavOpen(false);
    // }
    // const navStyle = {
    //     width: isNavOpen ? '260px' : '0',
    //     display: isNavOpen ? 'block' : 'none',
    // };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const navStyle = {
        width: isNavOpen || windowWidth >= 992 ? '260px' : '0',
        display: isNavOpen || windowWidth >= 992 ? 'block' : 'none',
    };

    // if (windowWidth >= 992) {
    //     navStyle.width = '260px';
    //     navStyle.display = 'block';
    // } else if (windowWidth <= 992) {
    //     navStyle.width = '-260px';
    // }


    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        
        getUserProfileDetails();
        window.addEventListener('resize', handleResize); 
        return () => window.removeEventListener('resize', handleResize);
        
    }, []);

    
    return (
        <div>
            <div id="wrapper">
                <div className="d-flex d-lg-none d-block">
                    <button className="button-menu-mobile waves-effect waves-light" onClick={toggleNav} >
                    {isNavOpen ? <CancelRounded /> : <MenuRounded />}
                    </button>
                    <div className="sdlogo">
                        <img src="assets/images/logo.png" alt="" height="60" />
                    </div>
                </div>
                <div className="left-side-menu" style={navStyle}>
                    <a href="#" id="side-nav-close" onClick={toggleNav}>
                        <CancelRounded />
                        </a>

                    <div className="slimscroll-menu1">
                        <div id="sidebar-menu1">
                            <div className="logo text-center"><Link to='/'><img src="assets/images/logo.png" alt="" height="60" /></Link></div>
                            <div className="d-flex mx-3 mt-2 prfbox">
                                <div className="align-self-center"> 
                                {(userDetails.profile_image)?<>
                                    <img src={BaseURL+ userDetails.profile_image} className="img-fluid d-block rounded-circle avatar-md" alt="user" />
                                
                                </>:<>
                                   <div style={{fontSize:"33px",textTransform:"uppercase",color:"blue",borderRadius:"100px",height:"70px",width:"70px",textAlign:"center",padding:"11px",background:"#eee",}}>
                              {userDetails?.first_name  && userDetails?.first_name.charAt(0)}{userDetails?.last_name  && userDetails?.last_name.charAt(0)}
                                   </div>
                                </>}
                                {/* <img src="assets/images/avatar-1.jpg" className="img-fluid d-block rounded-circle avatar-md" alt="user" /> */}
                                </div>
                                <div className="wid-u-info pl-2">
                                    <h5 className="mb-1">{userDetails.first_name}</h5>
                                    <p className="mb-0">{userDetails.designation}</p>
                                </div>
                            </div>
                            <ul className="usernewstepsnomenu">
                                <li className="compstep">
                                    <Link to="/dashboard"><i className="fe-grid fa-lg"></i> Dashboard <span className={(props.currentPage === 'dashboard') ? "circle completed": "circle"}><i className="fe-grid fa-lg"></i></span></Link></li>
                                <li>
                                    <Link to="/userprofile">
                                        <i className="fe-user fa-lg"></i> Profile <span  className={(props.currentPage === 'userprofile') ? "circle completed": "circle"}><i className="fe-user fa-lg"></i></span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/packages">
                                        <i className="fe-box fa-lg"></i> Packages <span  className={(props.currentPage === 'packages') ? "circle completed": "circle"}><i className="fe-box fa-lg"></i></span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/payments_transactions">
                                        <i className="fe-dollar-sign fa-lg"></i> Payments <span className={(props.currentPage === 'payments_transactions') ? "circle completed": "circle"}><i className="fe-dollar-sign fa-lg"></i></span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/totalprojects">
                                        <i className="fe-battery-charging fa-lg"></i> Projects <span  className={(props.currentPage === 'totalprojects') ? "circle completed": "circle"}><i className="fe-battery-charging fa-lg"></i></span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/visuallanding'>
                                        <i className="fe-eye fa-lg"></i> Visualisation Tool <span  className={(props.currentPage === 'visualisation-landing-page') ? "circle completed": "circle"}><i className="fe-eye fa-lg"></i></span>
                                    </Link>
                                </li>   
                                <li>
                                    <Link to="/support">
                                        <i className="fe-comment fa-lg"></i> Help & Support <span  className={(props.currentPage === 'support') ? "circle completed": "circle"}><i className="fe-help-circle fa-lg"></i></span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/feedback">
                                        <i className="fe-message-square fa-lg"></i> Feedback <span  className={(props.currentPage === 'feedback') ? "circle completed": "circle"}><i className="fe-message-square fa-lg"></i></span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cms-pages">
                                        <i className="fe-list fa-lg"></i> CMS Pages <span  className={(props.currentPage === 'cms-pages') ? "circle completed": "circle"}><i className="fe-list fa-lg"></i></span>
                                    </Link>
                                </li>
                             
                                <li>
                                    <Link to='/user-manual'>
                                        <i className="fe-file fa-lg"></i> User Manual <span  className={(props.currentPage === 'user-manual') ? "circle completed": "circle"}><i className="fe-file fa-lg"></i></span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/logout"} onClick={handleLogout} >
                                        <i className="fe-power fa-lg"></i> Logout <span className="circle"><i className="fe-power fa-lg"></i></span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withCookies(Userdashsidebar)
