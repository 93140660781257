import React from "react";
import { BaseURL, uploadFileOrImage } from "../../globalURL";

export default function FileUpload(props) {
  // console.log("props", props.fileDocName);
  const { formData, setFormData, fileDocName, isReadonlyEnable} = props;
  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("sss",data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["fileDocNames"]: data["data"]["full_file_address"],
          // ["fileDocName"]: data["data"]["full_file_address"],
        });
        // console.log('fileDocName', data["data"]["full_file_address"]);
      });

    // console.log("fileDocName", fileDocName);
  }

  return (
    <>
      <label className="text-white">Documents Upload</label>
      <div className="form-floating mb-0">
        <input
          disabled={isReadonlyEnable}
          type="file"
          className="filestyle form-control py-1 px-2"
          name={fileDocName}
          onChange={handleFileUpload}
        />
      </div>
      {fileDocName}
      {formData.fileDocNames ? (
        <>
          <p style={{ fontWeight: "bold", color: "yellow" }}>
          View document: &nbsp;
            <a
              rel="noreferrer"
              style={{ color: "#FFFF00" }}
              // href={`${BaseURL}${fileDocName}`}
              href={`${BaseURL}${formData.fileDocNames}`}
              target="_blank"
            >
              Click Here
            </a>
          </p>
        </>
      ) : ""}
      

    </>
  );
}
