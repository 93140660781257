import React, { useEffect, useRef, useState } from "react";
import "./landingcss/style.min.css";
import "./landingcss/project.css";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
import AnchorLink from "react-anchor-link-smooth-scroll";
import Anchormenu from "./Anchormenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import starImg from "./landingcss/4stars.png";
import classAImg from "./landingcss/class-a-star.png";
import goldImg from "./landingcss/gold.png";
// import project1 from "./landingcss/project01.jpg"
// import project2 from "./landingcss/project02.jpeg"
// import project3 from "./landingcss/project03.jpg"
import axios from "axios";
import {
  BaseURL,
  landingPageDataURL,
  packagesUrl,
  sendContactEmailUrl,
  sendSubscribeEmailUrl,
  websiteVisitCountIncrementUrl,
} from "../../../globalURL";
import { toast } from "react-toastify";
import { ArrowRightAlt } from "@mui/icons-material";

export default function LandingPage() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const dataFetchedRef = useRef(false);
  const [packagesData, setpackagesData] = useState([]);
  const [landingPageData, setlandingPageData] = useState([]);

  const [num, setNum] = useState(0);
  const [num2, setNum2] = useState(0);

  const BuyNowButton = () => {
    const handleBuyNow = () => {
      setIsLogin(true);
    };
    const linkDestination = isLogin ? "/packages" : "/login";

    const buttonText = "BUY NOW";

    return (
      <Link onClick={handleBuyNow} to={linkDestination} className="btn-buynow">
        {buttonText}
      </Link>
    );
  };

  function randomNumberInRange(min, max) {
    // ðŸ‘‡ï¸ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    FetchlandingPageData();
    fetchPackages();
    setNum(randomNumberInRange(1, 10));
    setNum2(randomNumberInRange(1, 99));
    updateVisitCount();
  }, []);
  const FetchlandingPageData = async (e) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        landingPageDataURL,
        JSON.stringify({ access_token: localStorage.getItem("access_token") })
      );
      setIsLoading(false);
      if (response?.data?.status === "valid") {
        // console.log("lpd", response.data.data);
        setlandingPageData(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // alert("An error occurred");
    }
  };
  const fetchPackages = async (e) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        packagesUrl,
        JSON.stringify({ access_token: localStorage.getItem("access_token") })
      );
      // console.log("response", response);
      setIsLoading(false);
      if (response?.data?.status === "valid") {
        // console.log("packagesDatal", response.data.data);
        setpackagesData(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // alert("An error occurred");
    }
  };
  //
  const updateVisitCount = async (e) => {
    try {
      const response = await axios.post(websiteVisitCountIncrementUrl, {});
      // console.log("response", response);
      if (response?.data?.status === "valid") {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const year = new Date().getFullYear();

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };
  const handleGettingStarted = () => {
    // localStorage.clear();
  };

  function openNav() {
    setIsNavOpen(true);
  }

  function closeNav() {
    setIsNavOpen(false);
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formDataErrors, setFormDataErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [subscribeData, setSubscribeData] = useState({
    email: "",
  });

  const [subscribeDataErrors, setSubscribeDataErrors] = useState({
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (formDataErrors[name] && value) {
      setFormDataErrors({ ...formDataErrors, [name]: "" });
    }
  };

  const handleSubscribeChange = (event) => {
    const { name, value } = event.target;
    setSubscribeData({ ...subscribeData, [name]: value });

    if (subscribeDataErrors[name] && value) {
      setSubscribeDataErrors({ ...subscribeDataErrors, [name]: "" });
    }
  };

  const validateFormData = () => {
    const errors = {};
    if (!formData.name) errors.name = "Please enter name";
    if (!formData.email) errors.email = "Please enter email";
    if (!formData.phone) errors.phone = "Please enter phone";
    if (!formData.message) errors.message = "Please enter your message";
    return errors;
  };

  const validateSubscribeMail = () => {
    const errors = {};
    if (!subscribeData.email) errors.email = "Subscription email required";
    return errors;
  };
  //if (!formData.subscribemail) errors.subscribemail = "Please enter subscribe mail";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      setSubmitted(false);
      return;
    }

    console.log("formData", formData);
    try {
      const response = await axios.post(
        sendContactEmailUrl,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status == 200) {
        toast.success(response.data.message);
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something error occured..!, Please try again.");
      console.log(error);
    }
  };

  const handleSubscribe = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    const errors = validateSubscribeMail();
    if (Object.keys(errors).length > 0) {
      setSubscribeDataErrors(errors);
      setSubmitted(false);
      return;
    }

    // console.log("subscribe data", subscribeData);
    try {
      const response = await axios.post(
        sendSubscribeEmailUrl,
        JSON.stringify(subscribeData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status == 200) {
        toast.success(response.data.message);
        setSubscribeData({
          email: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something error occured..!, Please try again.");
      console.log(error);
    }
  };
  const [isdisableContactSubmit, setisDisableContactSubmit] = useState(true);
  const validateContactForm = (e) => {
    const { name, value } = e.target;
    // alert(value)
    let total = num + num2;
    if (total == value) {
      setisDisableContactSubmit(false);
    } else {
      setisDisableContactSubmit(true);
    }
  };
  return (
    <div>
      <nav id="header-navbar" className="navbar navbar-expand-lg py-3">
        <div className="container" id="home">
          <button
            className="navbar-toggler"
            type="button"
            id="side-nav-open"
            onClick={openNav}
          >
            <span className="lnr lnr-menu"></span>
          </button>
          <div className="collapse navbar-collapse pt-3" id="navbar-nav-header">
            <Anchormenu />
          </div>
        </div>
      </nav>
      <div
        id="side-nav"
        className="sidenav"
        style={{ width: isNavOpen ? "250px" : "0" }}
      >
        <a href="#" id="side-nav-close" onClick={closeNav}>
          &times;
        </a>
        <div>
          <ul className="sidemenu">
            <li>
              <AnchorLink href="#" onClick={closeNav}>
                Home
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#about" onClick={closeNav}>
                About
              </AnchorLink>
            </li>

            <li>
              <AnchorLink href="#features" onClick={closeNav}>
                Features
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#projects" onClick={closeNav}>
                Projects
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#3rdparty" onClick={closeNav}>
                3rd Party Certifications
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#subscriptions" onClick={closeNav}>
                Subscriptions
              </AnchorLink>
            </li>

            <li>
              <AnchorLink href="#contact" onClick={closeNav}>
                Contact
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
      <div id="side-search" className="sidenav">
        <a href="#" id="side-search-close">
          &times;
        </a>
        <div className="sidenav-content">
          <form action="">
            <div className="input-group md-form form-sm form-2 pl-0">
              <input
                className="form-control my-0 py-1 red-border"
                type="text"
                placeholder="Search"
                aria-label="Search"
              />
              <div className="input-group-append">
                <button
                  className="input-group-text red lighten-3"
                  id="basic-text1"
                >
                  <span className="lnr lnr-magnifier"></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="jumbotron d-flex align-items-center">
        <div className="container text-center">
          <h1 className="display-1 mb-3">
            <img src="landingimg/logo.png" alt="" height="150" loading="lazy" />
          </h1>
          <br />
          <Link
            to="https://www.youtube.com/watch?v=nzugvboSxnI"
            target="_blank"
            className="btn-getstarted"
            rel="noopener noreferrer"
          >
            Watch Video 
          </Link>
        </div>
      </div>
      <section id="about" className="bg-white abtsection">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="section-title pb-3 ">
                  {landingPageData && landingPageData.about_title}
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <img
                  src={BaseURL + landingPageData.about_img}
                  alt=""
                  className="img-fluid mb-3"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-6">
                {landingPageData && (
                  <div
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData.about_desc,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="subscriptionbox lt-green" id="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="section-title pb-3 ">
                {landingPageData && landingPageData.features_headding}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 features-holder">
              <div className="row">
                {/* {landingPageData &&
                  landingPageData.features &&
                  landingPageData.features.map((app, index) => {
                    return (
                      <>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-6 text-center mt-4">
                          <div className="greenblock">
                            <div className="">
                              <img src={BaseURL + app.image} alt="" />
                            </div>
                            <h4>{app.title}</h4>
                          </div>
                        </div>
                      </>
                    );
                  })} */}

                {landingPageData &&
                  landingPageData.features &&
                  landingPageData.features.map((app, index) => {
                    return (
                      <>
                        <div className="flipbox col-md-4 ">
                          <div className="service-box d-flex justify-content-center align-items-center">
                            <div className="service-icon limegreen ">
                              <div className="front-content ">
                                <i className="fa fa-trophy"></i>
                                <img
                                  className="mb-2 flipimage"
                                  src={BaseURL + app.image}
                                  alt=""
                                  loading="lazy"
                                />
                                <h3>{app.title}</h3>
                              </div>
                            </div>
                            <div className="service-content">
                              {/* <h3>{app.title}</h3> */}
                              <p style={{ textAlign: "justify" }}>
                                {app.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="subscriptionbox lt-green"
        id="projects"
        style={{
          backgroundImage: "url(landingimg/featuresbg.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="  justify-content-between align-items-center">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="section-title pb-3">
                {landingPageData && landingPageData.project_headding}
              </h2>
              <p
                className=" text-white"
                style={{ padding: "0px 100px", textAlign: "left" }}
              >
                <div
                  className="text-white"
                  dangerouslySetInnerHTML={{
                    __html: landingPageData.project_desc,
                  }}
                />
              </p>
            </div>
          </div>
          {/* <div className="row" id="landinProjects">

            <div className="col-md-12">
              <div className="row">
                {landingPageData &&
                  landingPageData.projects &&
                  landingPageData.projects.map((item) => (
                    <div
                      key={item._id.$oid}
                      className="col-lg-4 col-md-12 col-sm-12 col-4 text-center img__wrap mb-3"
                    >
                      <img
                        className="img__img"
                        src={BaseURL + item.image}
                        style={{ height: "315px", width: "100%" }}
                        loading="lazy"
                      />

                      <div className="img__description project-title shadow rounded" >
                        <p className="shadow bg-white rounded" style={{ textAlign: "left" }}>
                          <span style={{ fontWeight: "bold", fontSize: "18px", color: "#333f48", marginRight: "85px", }}>{item.title}

                          </span > <ArrowRightAlt style={{ backgroundColor: "rgb(51 63 72 / 50%)", color: "#FFF", "fontSize": "30px" }} />
                          <br />
                          <br />
                          GSAS Design and Build
                          <img style={{ marginLeft: "10px", marginBottom: "8px" }} src={starImg} alt="" loading="lazy" />
                          <br />
                          GSAS Construction Management
                          <img style={{ marginLeft: "10px ", marginBottom: "8px", }} src={classAImg} alt="" loading="lazy" />
                          <br />
                          GSAS Operations
                          <img style={{ marginLeft: "10px ", marginBottom: "8px", }} src={goldImg} alt="" loading="lazy" />
                        </p>
                        <h4 className="text-white">
                        </h4>
                      </div>









                    </div>
                  ))}
              </div>
            </div>


          </div> */}

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="row">
                  {landingPageData &&
                    landingPageData.projects &&
                    landingPageData.projects.map((item) => (
                      <div className="col-lg-4" key={item._id.$oid}>
                        <div className="newprojectbox">
                          <a href="#">
                            <img
                              src={BaseURL + item.image}
                              alt=""
                              style={{
                                width: "100%",
                                height: "400px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                            <div className="project-contentbox shadow">
                              <div className="d-flex justify-content-between align-self-center">
                                <h3
                                  className="project-title-head"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {item.title}
                                </h3>
                                {/* <span><i className="la la-angle-right"></i></span> */}
                              </div>
                              <div className="contentbelow">
                                <div className="row">
                                  <div className="col-12">
                                    <h6 className="">Arcadia Certified</h6>
                                    {/* <h6>GSAS Design & Build <img src="" alt="" style={{marginLeft: "10px", marginBottom: "8px"}} /></h6> */}
                                  </div>
                                </div>
                                {/* <div className="row hide mt-2">
                                  <div className="col-4">
                                    <span className="greentxt">RATING</span>
                                    <p>4 STAR</p>
                                  </div>
                                  <div className="col-4">
                                    <span className="greentxt">SCHEME</span>
                                    <p>Construction Site</p>
                                  </div>
                                  <div className="col-4">
                                    <span className="greentxt">
                                      CERTIFIED ON
                                    </span>
                                    <p>Sept 10, 2020</p>
                                  </div>
                                </div> */}
                              </div>

                              {/* <div className="contentbelow">
                            <div className="row">
                                <div className="col-12">
                                    <h6>GSAS Construciton Management <img src="https://gsas.gord.qa/wp-content/themes/hank/assets/img/projects/class-a-star.png" alt="GSAS-CM rating" /></h6>
                                </div>
                            </div>
                            <div className="row hide">
                                <div className="col-4">
                                    <span className="greentxt">RATING</span>
                                    <p>CLASS A+</p>
                                </div>
                                <div className="col-4">
                                    <span className="greentxt">SCHEME</span>
                                    <p>Construction Site</p>
                                </div>
                                <div className="col-4">
                                    <span className="greentxt">CERTIFIED ON</span>
                                    <p>Aug 20, 2020</p>
                                </div>
                            </div>
                        </div>
                        <div className="contentbelow">
                            <div className="row">
                                <div className="col-12">
                                    <h6>GSAS Operations <img src="https://gsas.gord.qa/wp-content/themes/hank/assets/img/projects/gold.png" alt="GSAS-OP rating" /></h6>
                                </div>
                            </div>
                            <div className="row hide">
                                <div className="col-4">
                                    <span className="greentxt">RATING</span>
                                    <p>GOLD</p>
                                </div>
                                <div className="col-4">
                                    <span className="greentxt">SCHEME</span>
                                    <p>Operations Premium</p>
                                </div>
                                <div className="col-4">
                                    <span className="greentxt">CERTIFIED ON</span>
                                    <p>Sept 5, 2020</p>
                                </div>
                            </div>
                        </div> */}
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        className="subscriptionbox lt-green"
        id="3rdparty"
        style={{
          backgroundImage: "url(landingimg/iconfeaturesbg.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="">
            <div className="col-lg-12 text-center">
              <h2 className="certification text-center">
                Third-Party Certification
              </h2>
              <p>
                Gain recognition for your facilityâ€™s energy efficiency with
                Arcadia certification.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 features-holder">
              <div className="row">
                <img
                  className="mb-2 "
                  src={"landingimg/arcadia_certificate.jpg"}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <br />
          <br />
          <div className="row">
            <div className="col-lg-6 text-center">
              <img
                className="mb-2 "
                src={"landingimg/glod-cer.jpg"}
                alt=""
                style={{ height: "235px" }}
              />
            </div>
            <div className="col-lg-6">
              <h3 className="text-white">Why get third-party certification</h3>
              <p>
                As project owners, you strive to ensure that your
                energy-intensive projects perform optimally, not just for the
                benefit of your district cooling facility but also for the
                environment. Third-party verification and certification can help
                you showcase the energy performance of your facility to a wider
                audience. It's an opportunity to demonstrate your commitment to
                sustainability while also earning you a competitive edge in the
                market. The certification can act as a Measurement, Reporting
                and Verification (MRV) tool for your journey towards energy
                transition and net zero.
              </p>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-6">
              <h3 className="text-white">What is Arcadia certification?</h3>
              <p>
                Arcadia certification is a specialized accreditation that
                evaluates and recognizes the energy performance and efficiency
                of District Cooling Plants (DCPs) and onsite chilled water
                systems. This certification provides comprehensive assessment of
                the efficiency and effectiveness of all energy-intensive systems
                integrated within DCPs and chillers, acknowledging their ability
                to optimize energy usage and contribute to sustainable
                practices. Arcadia certification sets a benchmark for excellence
                in energy management and showcases the dedication of facilities
                towards creating a greener and more sustainable future.
              </p>
            </div>
            <div className="col-lg-6 text-center">
              <img
                className="mb-2 "
                src={"landingimg/arcadia_report_mockup.jpg"}
                alt=""
                style={{ height: "235px" }}
              />
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-6 text-center">
              <img
                className="mb-2 "
                src={"landingimg/arcadia_certificate.jpg"}
                alt=""
                style={{ height: "235px" }}
              />
            </div>
            <div className="col-lg-6">
              <h3 className="text-white">
                How to obtain Arcadia certification?
              </h3>
              <p>
                To earn the coveted Arcadia certification, project owners are
                required to contact us to be familiarized with the specific
                criteria and requirements for the certification. Upon
                confirmation of the projectâ€™s eligibility, the project will
                undergo energy assessment and verification through our
                comprehensive Arcadia Suite and our team of energy experts at
                Arcadia. This innovative software solution streamlines the
                verification process, ensuring accurate and reliable assessments
                of a project's energy efficiency. Once the verification process
                is complete, GORD issues the Arcadia certification, identifying
                the rating achieved by your project based on its performance
                against specified benchmarks. Depending on the overall energy
                consumption of the project, four different ratings (, Silver,
                Gold, Platinum and Diamond) can be achieved.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section
        className="subscriptionbox lt-green"
        id="3rdparty"
        style={{
          background: "#011655",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="col-md-12">
            <div className="">
              <div className="text-justify">
              <h2 className="text-white text-center ">
                  {landingPageData && landingPageData.third_party_certification_title}
                </h2>
                <p
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData?.third_party_certification_desc,
                    }}
                  />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-6">
                <br />
                <br />
                <h3 className="text-white">
                {landingPageData && landingPageData.what_arcedia_certification_title }

                </h3>
                <p
                 style={{ textAlign: "justify" }}
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData?.what_arcedia_certification_desc,
                    }}
                  />
              </div>
              <div className="col-lg-6 text-center">
              <img
                  src={
                    BaseURL + landingPageData?.what_arcedia_certification_image
                  }
                  style={{ height: "400px" }}
                  alt=""
                  className="img-fluid mb-2"
                  loading="lazy"
                />
              </div>
              <div style={{ height: "40px" }} className={"col-lg-12"}>
                
              </div>
              <div className="col-lg-6 text-center">
              <img
                  src={
                      BaseURL +
                        landingPageData.who_issue_arcedia_certification_image
                  }
                  alt=""
                  className="img-fluid mb-2"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-6">
              <h3 style={{ color: "white" }}>
                  {landingPageData && landingPageData?.who_issue_arcedia_certification_title}
                </h3>

                <p
                 style={{ textAlign: "justify" }}
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData?.who_issue_arcedia_certification_desc,
                    }}
                  />
              </div>
            </div>
            <br />
            <br />
            <div style={{ height: "40px" }} className={"col-lg-12"}>
              
            </div>
            <div className="row">
              <div className="col-lg-6">
              <h3 className="text-white">
                {landingPageData && landingPageData?.how_to_obtain_arcedia_certification_title}

                </h3>
                <p
                 style={{ textAlign: "justify" }}
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData?.how_to_obtain_arcedia_certification_desc,
                    }}
                  />
              </div>
              <div className="col-lg-6 text-center">
              <img
                  src={
                   BaseURL +
                        landingPageData.how_to_obtain_arcedia_certification_image
                  }
                  alt=""
                  className="img-fluid mb-2"
                  loading="lazy"
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="">
              <h3 className="text-white">
                {landingPageData && landingPageData?.label_plaque_cerficate_title}

                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <br />
                <p style={{ color: "white", fontWeight: "bold" }}>
                {landingPageData && landingPageData?.label_title}

                </p>
                <p
                 style={{ textAlign: "justify" }}
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData?.label_desc,
                    }}
                  />
              </div>
              <div className="col-lg-12">
              <p style={{ color: "white", fontWeight: "bold" }}>
                {landingPageData && landingPageData?.plaque_title}

                </p>
                <p
                 style={{ textAlign: "justify" }}
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData?.plaque_desc,
                    }}
                  />
              </div>
              <div className="col-lg-12">
              <p style={{ color: "white", fontWeight: "bold" }}>
                {landingPageData && landingPageData?.certificate_title}

                </p>
                <p
                 style={{ textAlign: "justify" }}
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: landingPageData?.certificate_desc,
                    }}
                  />
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>

      <section className="subscriptionbox" id="subscriptions">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2
                style={{ fontSize: "26px", fontWeight: "600" }}
                className="section-title"
              >
                {landingPageData && landingPageData.sub_plan_title}
              </h2>
            </div>
          </div>
          <div className="row justify-content-center pt-3">
            {/* {packagesData &&
              packagesData.map((app) => (
                <div className="col-lg-6" key={app._id}>
                  <div
                  
                    style={{ backgroundColor: "#5d78ff" }}
                    className="planpackagebox"
                  >
                    <h4 className="my-4">{app.title} </h4>

                    <Link className="btn-buynow" to="/packages">
                      BUY NOW
                    </Link>
                  </div>
                </div> 
              ))} */}

            <p>
              {landingPageData && (
                <div
                  className="text-black"
                  dangerouslySetInnerHTML={{
                    __html: landingPageData.sub_plan_desc,
                  }}
                />
              )}
            </p>
            {/* <p className="pb-3">
              Join the growing number of project stakeholders who have relied on Arcadia Suite. Choose
               from our flexible subscription plans that fit your energy assessment needs and budget</p> */}
            {packagesData &&
              packagesData.map((app,index) => {
                return (
                  <>
                    <div key={app._id} className="col-lg-4">
                      <div className="card subcard ">
                        <div className="card-header">
                        {(index === 0)? <>
                          <h3  style={{
                              fontWeight: "700",
                             fontSize:"1.125rem",
                              width: "21rem",
                              padding: "0px 0px", 
                              textAlign: "center",
                            }}
                            className="my-2"> {(index === 0)? "Self-Declaration ":""}</h3>
                        </>:""}
                     
                          <h4
                            style={{
                              fontWeight: "700",
                              borderBottom: "1px",
                              width: (index === 0)?  "" : "19rem",
                              lineHeight: (index === 0)?  "" : "27px",
                              padding:  (index === 0)?  "0px 0px" :"0px 50px", 
                              textAlign: "center",
                            }}
                            className="my-2"
                          >
                            
                            {app.title}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div
                            className="row justify-content-center mb-4 text-center"
                            style={{ height: "101px" }}
                          >
                            {app &&
                              app.sub_categories.map((sub, index) => {
                                return (
                                  <>
                                    <div key={index} className="col-md-8">
                                      <h4> {sub.title}</h4>
                                    </div>
                                    <div
                                      style={{
                                        height: index % 2 !== 0 ? "0px" : "2px",
                                        margin:
                                          index % 2 !== 0 ? "0px" : "10px  0px",
                                        width:
                                          index % 2 !== 0 ? "0px" : "15rem",
                                        background: " #001655",
                                        borderTop:
                                          index % 2 === 0
                                            ? "0px"
                                            : "1px solid #001655",
                                        marginBottom: "5px",
                                      }}
                                    ></div>
                                  </>
                                );
                              })}
                          </div>
                          <div className="row justify-content-center">
                            {/* <Link className="btn-buynow" to="/packages">
                              BUY NOW
                            </Link> */}
                            {BuyNowButton()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </section>

      <section id="contact" className="section-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-section text-center">
                <h2 className="mb-4">Stay in touch</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-12 mb-5 mb-lg-0" data-aos="fade-right">
              <div className="p-4">
                <form
                  method="post"
                  name="contact-us"
                  onSubmit={handleSubmit}
                  action=""
                >
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control"
                        id="name"
                        placeholder="Name"
                      />
                      {formDataErrors.name && (
                        <div className="text-danger">{formDataErrors.name}</div>
                      )}
                    </div>
                    <div className="col-md-12 form-group">
                      <input
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                      />
                      {formDataErrors.email && (
                        <div className="text-danger">
                          {formDataErrors.email}
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 form-group">
                      <input
                        type="number"
                        onChange={handleChange}
                        value={formData.phone}
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                      />
                      {formDataErrors.phone && (
                        <div className="text-danger">
                          {formDataErrors.phone}
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 form-group">
                      <textarea
                        onChange={handleChange}
                        value={formData.message}
                        className="form-control"
                        id="message"
                        name="message"
                        rows="4"
                        placeholder="Your Message ..."
                      ></textarea>
                      {formDataErrors.message && (
                        <div className="text-danger">
                          {formDataErrors.message}
                        </div>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-2 text-right">
                          <h3 className="text-white unselectable">
                            
                            {num} + {num2} =
                          </h3>
                        </div>
                        <div className="col ">
                          <input
                            type="number"
                            onChange={validateContactForm}
                            className="form-control"
                            id="validateFormInput"
                            name="validateFormInput"
                            placeholder="Enter the value"
                          />
                        </div>
                      </div>

                      <button
                        className="btn btn-primary btn-shadow btn-lg"
                        type="submit"
                        name="submit"
                        disabled={isdisableContactSubmit}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
            </div>
            {/* <div className="col-lg-6" data-aos="fade-left">
              <h4 className="mb-4">
                Best solution to calculate energy efficiency of cooling plants.
              </h4>
              <p className="mb-4">
                Arcadia suite - a technology with great potential to evaluate
                energy efficiency of district cooling plants. This tool also
                calculates seasonal energy efficiency of district plants.
              </p>
              <div className="row coniconbox">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <img src="landingimg/conicon-1.jpg" alt="" />
                  <h5>On time report</h5>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <img src="landingimg/conicon-2.jpg" alt="" />
                  <h5>Accurate calculation </h5>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <img src="landingimg/conicon-3.jpg" alt="" />
                  <h5>Easy to use</h5>
                </div>
              </div>
              <a href="#subscriptions" className="btn-purchase">
                PURCHASE NOW
              </a>
            </div> */}
          </div>
        </div>
      </section>

      <footer className="mastfoot">
        <div className="inner container">
          <div className="row justify-content-between pt-4 pb-4">
            <div className="col-lg-4 col-12 align-self-center">
              <p> &copy; Copyright {year} Arcadia. All Rights Reserved</p>
            </div>

            <div className="col-lg-6 col-12">
              <form method="post" onSubmit={handleSubscribe} action="">
                <div className="d-flex subinput align-items-center">
                  <h6>Subscribe Newsletter </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Address"
                    value={subscribeData.email}
                    name="email"
                    onChange={handleSubscribeChange}
                  />
                  <button type="submit" className="btn-subscribe">
                    SUBSCRIBE
                  </button>
                </div>
                {subscribeDataErrors.email && (
                  <div className="text-danger text-center">
                    {subscribeDataErrors.email}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
