import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BaseURL,
  designPhaseCalcUrlUsingProjectId,
  projectDetails,
} from "../../globalURL";
import Dashboardsidebar from "./Dashboardsidebar";
import { format } from "date-fns";
import Spinner from "./Spinner"; //"./project/Spinner";
import DesignPhase from "./result_components/DesignPhase";
import AsOperated from "./result_components/AsOperated";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Spinner2 from "./Spinner2";

const Resultasbuilt = () => {
  const dataFetchedRef = useRef(false);
  const componentToPrintRef = useRef();

  const queryParams = new URLSearchParams(window.location.href);
  const projectId = queryParams.get("project_id");
  console.log("projectId : " + projectId);

  const navigate = useNavigate();
  const [result, setResult] = useState({
    chiller_seer_kw: "-",
    chiller_seer_kw_ton: "-",
    img1: "",
    img2: "",
    system_seer_kw: "-",
    system_seer_kw_ton: "-",
    time_difference: "-",
    gsas_energy_efficiency_op: "-",
    system_performance_op: "-",
    design_letterrating: "",
    trh: "-",
    system_seer_op: "-",
    op: "",
    country_id: "",
    city_id: "",
  });

  const [singleProjectDetails, setsingleProjectDetails] = useState({
    _id: "",
    project_phase: "",
    project_date: "",
    project_name: "",
    project_location: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    getProjectDetails();
    //
  }, []);

  const sendformData = async () => {
    setIsLoading(true);
    // return False
    axios
      .post(
        designPhaseCalcUrlUsingProjectId,
        JSON.stringify({
          project_id: projectId,
          access_token: localStorage.getItem("access_token"),
        }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        console.log(response.data.data);
        setResult(response.data.data);
        getProjectDetails();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // alert("failed");
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      });
  };
  const getProjectDetails = async () => {
    setIsLoading(true);
    await axios
      .post(projectDetails, JSON.stringify({ project_id: projectId }), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsLoading(false);
        // console.log(res["data"]["data"]);
        let result = res["data"]["data"];
        console.log("result", result);
        setsingleProjectDetails(result);
        if (result["project_status"] === "pending") {
          // alert("PENDING");
          sendformData();
        } else if (result["project_status"] === "completed") {
          //completed
          // alert("Completed");
          setResult({
            chiller_seer_kw: result["chiller_seer_kw"],
            chiller_seer_kw_ton: result["chiller_seer_kw_ton"],
            img1: result["img1"],
            img2: result["img2"],
            system_seer_kw: result["system_seer_kw"],
            system_seer_kw_ton: result["system_seer_kw_ton"],
            time_difference: result["time_difference"],
            gsas_energy_efficiency_op: result["gsas_energy_efficiency_op"],
            system_performance_op: result["system_performance_op"],
            system_seer_op: result["system_seer_op"],
            design_letterrating: result["design_letterrating"],
            trh: result["trh"],
            op: "",
            country_id: result["country_id"],
            city_id: result["city_id"],
          });
        } else {
          // cancelled
        }
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleDownloadPdf = async () => {
    const button = document.querySelector(".exportbutton");
    button.style.display = "none";

    const element = componentToPrintRef.current;

    // Get the dimensions of the page
    const pageWidth = element.offsetWidth;
    const pageHeight = 2322;
    // alert( element.offsetHeight)
    const canvas = await html2canvas(element, {
      backgroundColor: "#00000f",
      useCORS: true,
      width: pageWidth, // Set canvas width to page width
      height: pageHeight, // Set canvas height to page height
    });

    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Result.pdf");
    button.style.display = "block";
  };
  return (
    <div style={{ backgroundColor: "#04257B" }}>
    <div style={{ backgroundColor: "#04257B"}}>
      {isLoading ? (
        <Spinner2 />
      ) : (
        <>
          <Dashboardsidebar currentPage={"resultasbuilt"} />
          <div className="content-page mt-0 py-2">
            <div className="content">
              <div className="container-fluid">
                <div
                  className="row justify-content-center"
                  ref={componentToPrintRef}
                >
                  <div className={(singleProjectDetails.project_phase === 'Design Phase') ? 'col-lg-7 col-md-12 mb-lg-0 mb-2' : 'col-lg-10 col-md-12 mb-lg-0 mb-2'}  >
                    <div style={{ backgroundColor: "#04257b", borderRadius: "12px", padding: "14px", border: "1px solid #c1ff00" }}>
                    <div className="card transparent mb-0">
                      <div className="card-body pt-1 pb-0">
                        <div className="row justify-content-center">
                          <div className="col-12">


                            <div className="row mb-1 d-flex justify-content-between">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="page-title-box">
                                  {/* <h4 className="page-title pb-0">Result</h4> */}
                                  <img
                                    src="assets/images/logo.png"
                                    alt=""
                                    width={120}
                                    height={61}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-6 col-6 align-self-center text-right">
                                <img src="assets/images/logo-1.png" alt="" width={180} height={68} style={{ objectFit: 'contain' }} />
                              </div>
                            </div>
                            <div style={{ height: "16px" }}></div>
                            <div className="row mb-1 d-flex justify-content-between">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="page-title-box">
                                  <h4 className="page-title pb-0" style={{ lineHeight: '25px' }}>Result Page</h4>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-6 col-sm-6 col-6 text-right">
                                <button
                                  onClick={handleDownloadPdf}
                                  className="btn btn-primary exportpdf"
                                  type="button"
                                >
                                  Export PDF 
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row resultproject">
                          <div className="col-6 mb-2">
                            <h4 style={{ fontSize: "14px", fontFamily: "Poppins-Medium", fontWeight:600}}>
                              <i
                                style={{ color: "white" }}
                                className="mdi mdi-arrow-right font-18"
                              ></i>
                              &nbsp;Project ID
                            </h4>
                            <p
                              style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Poppins-Bold"}}
                              className=""
                            >
                              {singleProjectDetails._id &&
                                singleProjectDetails._id}
                            </p>
                          </div>

                          <div className="col-6 mb-2">
                            <h4 style={{ fontSize: "14px", fontFamily: "Poppins-Medium",fontWeight:600 }}>
                              <i
                                style={{ color: "white" }}
                                className="mdi mdi-arrow-right font-18"
                              ></i>
                              &nbsp; Location
                            </h4>
                            <p style={{ fontSize: "16px", fontFamily: "Poppins-Bold",fontWeight:600 }}>
                              {singleProjectDetails?.country_id},
                              {singleProjectDetails?.city_id}
                            </p>
                          </div>

                        </div>

                        <div className="row resultproject">
                          <div className="col-6 mb-1">
                            <h4 style={{ fontSize: "14px",  fontFamily: "Poppins-Medium", fontWeight:600}}>
                              <i
                                style={{ color: "white", fontFamily: "Poppins", fontWeight: 600 }}
                                className="mdi mdi-arrow-right font-18"
                              ></i>
                              &nbsp; Project Name
                            </h4>
                            <p style={{ fontSize: "16px", fontFamily: "Poppins-Bold",fontWeight:600 }}>
                              {singleProjectDetails?.project_name}
                            </p>
                          </div>

                          <div className="col-6">
                            <h4 style={{ fontSize: "14px", fontFamily: "Poppins-Medium",fontWeight:600 }}>
                              <i
                                style={{ color: "white" }}
                                className="mdi mdi-arrow-right font-18"
                              ></i>
                              &nbsp;Date
                            </h4>
                            <p style={{ fontSize: "16px", fontFamily: "Poppins-Bold",fontWeight:600 }}>
                              {singleProjectDetails?.project_date}
                            </p>
                          </div>
                        </div>

                        {/* <hr /> */}
                        <hr
                          style={{
                            border: "2px solid #0f7617",
                            marginBottom: "20px", marginTop: '10px'
                          }}
                        />

                        {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(result, null, 2)}
                    </pre> */}
                        {singleProjectDetails.project_phase ===
                          "Design and As Operated" && (
                            <div className="row">
                              <div className="col-md-6">
                                <DesignPhase result={result} />
                              </div>
                              <div className="col-md-6">
                                <AsOperated result={result} />
                              </div>
                            </div>
                          )}
                        {singleProjectDetails.project_phase ===
                          "Design Phase" && (
                            <div className="row">
                              <div className="col-md-12">
                                <DesignPhase result={result} />
                              </div>
                            </div>
                          )}
                        {singleProjectDetails.project_phase ===
                              "As Operated" && (
                              <div className="row" style={{height: '50vh'}}>
                                <div className="col-md-12">
                                  <AsOperated result={result} />
                                </div>
                              </div>
                            )}

                        <div className="form-group row justify-content-end mt-1">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left prj">
                            <button
                              style={{ backgroundColor: "#68b90b" }}
                              type="submit"
                              onClick={() => navigate("/dashboard")}
                              className="btn text-light mr-1 waves-effect waves-light previousbtn"
                            >
                              Return to dashboard
                            </button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right prj"></div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>

                  {singleProjectDetails.project_phase === "Design Phase" && (
                    <div
                      // style={{ marginTop: "5rem" }}
                      className={(singleProjectDetails.project_phase === "Design Phase") ? "col-lg-4 col-md-4 mb-5" : "col-lg-4 col-md-12 mb-5"}
                      style={{ display: singleProjectDetails.project_phase === "As Operated" ? "none" : "block" }}
                    >
                      <div
                        style={{ borderRadius: "10px", backgroundColor: "#06267a", padding: "1.75rem", border: "1px solid #c1ff00" }}
                        className=" text-center mb-4"
                      >
                        <img
                          // style={{backgroundColor: "#06267a", height:"500px"}}
                          src={BaseURL + result.img1}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div
                        style={{ borderRadius: "10px", backgroundColor: "#06267a", padding: "1.75rem", border: "1px solid #c1ff00" }}
                        className=" text-center"
                      >
                        <img

                          src={BaseURL + result.img2}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div style={{height:"15px"}}></div>
                <div className='row justify-content-center'>
                <div className="col-lg-10 px-0">
                  {singleProjectDetails.project_phase === "Design and As Operated" && (<div className="row text-center">
                    <div className="col-6">
                      <div
                        style={{ borderRadius: "10px", backgroundColor: "#06267a", padding: "1.75rem", border: "1px solid #c1ff00" }}
                        className="text-center"
                      >
                        <img
                          src={BaseURL + result.img1}
                          alt=""
                          className="img-fluid"
                        />
                      </div> 
                    </div> 
                    <div className="col-6">
                      <div
                        style={{ borderRadius: "10px", backgroundColor: "#06267a", padding: "1.75rem", border: "1px solid #c1ff00" }}
                        className="text-center"
                      >
                        <img

                          src={BaseURL + result.img2}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  )}
                </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

    </div>
    </div>
  );
};

export default Resultasbuilt;
