import React from 'react';

const DownloadButton = (props) => {

    const handleDownload = async () => {
        if (props.url)
            try {
                const response = await fetch(props.url);
                const blob = await response.blob();
                let fileName = 'gasa_arcadia_file_downlaod.pdf';
                if (props.file_name) {
                    fileName = props.file_name;
                }
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.append(link);
                link.click();
                link.remove();
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            } catch (error) {
                console.error('Failed to download file:', error);
            }
    };

    return (
        <button onClick={handleDownload}>Download PDF</button>
    );
};

export default DownloadButton;
