import React, { useEffect } from 'react'
import { withCookies } from 'react-cookie';
import { Link, useNavigate } from "react-router-dom";
const Logout = () => {
    const navigate = useNavigate()
    useEffect(()=>{
        localStorage.clear();
        navigate("/login")
    })
    return (
        <div>
            <h1>Logout</h1>
        </div>
    )
}

export default withCookies(Logout);
