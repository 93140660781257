import React,{useState} from 'react';
import axios  from 'axios';
import '../css/main.css';
import '../css/login.css';
import '../css/logincustom.css';
import '../css/structure.css';
import { Link,useNavigate,useLocation} from "react-router-dom";
import { resendOtpURL, verifyOtpURL } from '../globalURL';
import { toast } from 'react-toastify';


const Otp = () => {

  const [formData, setFormData] = useState({
    email: localStorage.getItem("email"),
})
 

    const navigate = useNavigate();
    const {email} = formData || {}
  const [data, setData] = useState('');
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');

  
 

  //   const generateOTP = () => {
  //   return Math.floor(100000 + Math.random() * 900000).toString();
  // };


  const handleVerifyOTP = async (event) => {
    event.preventDefault();

    if (!otp) {
      setError('Please enter OTP');
      return;
    }

    try {
      const response = await axios.post(verifyOtpURL, {email, otp});
      const detail = response.data;
      // console.log('detail', detail);

      if (response.data.status === "valid") {
        toast.success(`${response.data.message}`);
        navigate('/login');
    }else{
      // alert(`${response.data.message}`);
      toast.error(`${response.data.message}`);
    }
  } catch (err) {
      setError('Failed to verify OTP');
    }
  };




  const handleResendOTP = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(resendOtpURL, {email});
      // console.log('response', response.data);
      toast.success('OTP has been resent successfully');
    } catch (error) {
      setError('Failed to resend OTP');
    }
  };
  

  return (
    <div>
      <div className="login-one">
    <div className="container-fluid login-one-container">
        <div className="p-30" >
            <div className="row main-login-one justify-content-center">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 p-0">
                    <div className="login-one-start">
                       <div className="text-center py-4"> <img src="assets/images/logo.png" alt="" height="70"/></div>
                        <h6 className="mt-2 txt-yellow text-center font-20">OTP</h6>
                        <p className="text-center text-muted mt-2 mb-2 font-14">OTP has been sent to this email address </p>
                        <p className="text-center text-muted mt-2 mb-2 font-14">{email}</p>
                        <div className="login-one-inputs mt-4">
                            <input  id="otp" value={otp}  onChange={(event) => setOTP(event.target.value)} type="text" maxLength="4" placeholder="Enter OTP"/>
                           <span> <i className="las la-comment"></i> </span>
                        </div>
                       
                        <div className="login-one-inputs mt-2 text-center">
                            <button onClick={handleVerifyOTP} className="ripple-button ripple-button-primary btn-lg btn-login" type="button">
                                <div className="ripple-ripple js-ripple">
                                <span className="ripple-ripple__circle"></span>
                                </div>
                                SUBMIT
                            </button> 
                            {error && <p className='text-danger my-1'>{error}</p>}
                        </div>
                        <div className="login-one-inputs mt-3 text-center font-12 strong">
                            Didn't receive OTP Code? 
                            <Link onClick={handleResendOTP} relative='path' className="text-white"> Resend code
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>
    </div>
  )
}

export default Otp
