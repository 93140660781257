import React, { useState } from 'react'
import { BaseURL } from '../../../globalURL'
import CHILLERICON from "../../../images/icon1.png"
import SEERICON from "../../../images/icon2.png"

// import SEERICON from "../../../images/seericon_1.svg"

// import CHILLERICON from "../../../images/chillericon.svg"

const DesignPhase = (props) => {

    return (
        <div>
            {/* <h3 style={{padding:"15px",borderBottom:"1px solid white",color:"#fff",fontWeight:"bold"}}>Design Phase</h3> */}

            <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="row justify-content-between">
                    <div className="col-lg-10 col-md-10 col-sm-9 col-10">
                        <ul className="efficientbox">
                            <li className={(props.result.design_letterrating === 'A*') ? 'blink_me' : ''} style={{ "width": "30%" }}>A*</li>
                            <li className={(props.result.design_letterrating === 'A') ? 'blink_me' : ''} style={{ "width": "40%" }}>A</li>
                            <li className={(props.result.design_letterrating === 'B') ? 'blink_me' : ''} style={{ "width": "50%" }}>B</li>
                            <li className={(props.result.design_letterrating === 'C') ? 'blink_me' : ''} style={{ "width": "60%" }}>C</li>
                            <li className={(props.result.design_letterrating === 'D') ? 'blink_me' : ''} style={{ "width": "70%" }}>D</li>
                            <li className={(props.result.design_letterrating === 'E') ? 'blink_me' : ''} style={{ "width": "80%" }}>E</li>
                            <li className={(props.result.design_letterrating === 'F') ? 'blink_me' : ''} style={{ "width": "90%" }}>F</li>
                            <li className={(props.result.design_letterrating === 'G') ? 'blink_me' : ''} style={{ "width": "100%" }}>G</li>
                        </ul>
                    </div>

                    <div style={{borderRight: "3px solid #0f7617", height: "335px", position: "relative", right:'10px'}}  className="col-lg-2 col-md-2 col-2 col-sm-3" >
                    {/* <div > */}
                        <div className={`gradeAplus ${props.result.design_letterrating === "A*" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                        <div className={`gradeA ${props.result.design_letterrating === "A" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                        <div className={`gradeB ${props.result.design_letterrating === "B" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                        <div className={`gradeC ${props.result.design_letterrating === "C" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                        <div className={`gradeD ${props.result.design_letterrating === "D" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                        <div className={`gradeE ${props.result.design_letterrating === "E" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                        <div className={`gradeF ${props.result.design_letterrating === "F" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                        <div className={`gradeG ${props.result.design_letterrating === "G" ? 'visible' : 'hidden'}`}>{ props.result.design_letterrating}</div>
                    {/* </div> */}
                    <div style={{
                    position: "absolute",
                    top: "0",
                    left: "102%",
                    transform: "translate(-50%, 0)",
                    width: "8px",
                    height: "8px",
                    borderRadius: "100%",
                    background: "#bde31c",
                    content: "''",
                }} />

                <div style={{
                    position: "absolute",
                    bottom: "0",
                    left: "102%",
                    transform: "translate(-50%, 0)",
                    width: "8px",
                    height: "8px",
                    borderRadius: "100%",
                    background: "#bde31c",
                    content: "''",
                }} />
                    </div>
                </div>
            </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <div className="col-lg-12 col-md-12">
                    <div style={{backgroundColor: "#06267a", borderRadius: "16px", border: '3px solid #3f6522', marginRight: "0px",height:'80px'}} className="card widget-box-two mb-1">
                        <div className="card-body py-2 px-3">
                            <div className="media">
                                <div  className="media-body wigdet-two-content">
                                    <p className="m-0 fz-20" style={{fontFamily: "DINNextLTPro-Condensed",color:''}} >System SEER</p>
                                    <h2 className="systemseerval" style={{fontFamily: "DINNextLTPro-BoldCondensed"}}><span data-plugin="counterup">{Number(props.result.system_seer_kw).toFixed(2) || 0}</span> <i className="mdi mdi-arrow-up font-22"></i></h2>
                                </div>
                                <div className="avatar-lg ml-2 align-self-center">
                                    {/* <i className="dripicons-archive font-22 avatar-title text-white"></i> */}
                                    <img className='iconImage' src={SEERICON} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div style={{backgroundColor: "#06267a", borderRadius: "16px", border: '3px solid #3f6522', color: "#3f6522",height:'80px'}} className="card widget-box-two mb-1">
                        <div className="card-body py-2 px-3">
                            <div className="media">
                                <div className="media-body wigdet-two-content">
                                    <p className="m-0 fz-20" style={{fontFamily: "DINNextLTPro-Condensed"}}>System Performance (kW/ton)</p>
                                    <h2 className="systemseerval" style={{fontFamily: "DINNextLTPro-BoldCondensed"}}><span data-plugin="counterup">{Number(props.result.system_seer_kw_ton).toFixed(2) || 0}</span> <i className="mdi mdi-arrow-up font-22"></i></h2>
                                </div>
                                <div className="avatar-lg ml-2 align-self-center">
                                    {/* <i className="dripicons-archive font-22 avatar-title text-white"></i> */}
                                    <img className='iconImage' src={SEERICON} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div style={{backgroundColor: "#06267a", borderRadius: "16px", border: '3px solid #3f6522', color: "#3f6522",height:'80px'}} className="card widget-box-two mb-1">
                        <div className="card-body py-2 px-3">
                            <div className="media">
                                <div className="media-body wigdet-two-content">
                                    <p className="m-0  fz-20" style={{fontFamily: "DINNextLTPro-Condensed"}}>Chiller SEER</p>
                                    <h2 className="systemseerval" style={{fontFamily: "DINNextLTPro-BoldCondensed"}}><span data-plugin="counterup">{Number(props.result.chiller_seer_kw).toFixed(2) || 0}</span> <i className="mdi mdi-arrow-up font-22"></i></h2>
                                </div>
                                <div className="avatar-lg  ml-2 align-self-center">
                                    <img className='iconImage' src={CHILLERICON} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div style={{backgroundColor: "#06267a", borderRadius: "16px", border: '3px solid #3f6522',height:'80px' }} className="card widget-box-two mb-1">
                        <div className="card-body py-2 px-3">
                            <div className="media">
                                <div className="media-body wigdet-two-content">
                                    <p className=" m-0 fz-20" style={{fontFamily: "DINNextLTPro-Condensed"}}>Chiller Performance (kW/ton)</p>
                                    <h2 className="systemseerval" style={{fontFamily: "DINNextLTPro-BoldCondensed"}}><span data-plugin="counterup">{Number(props.result.chiller_seer_kw_ton).toFixed(2) || 0}</span> <i className="mdi mdi-arrow-up font-22"></i></h2>
                                </div>
                                <div className="avatar-lg  ml-2 align-self-center">
                                    <img className='iconImage' src={CHILLERICON} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">

              
                </div>
                </div>

            </div>

``
        </div>
    )
}

export default DesignPhase
