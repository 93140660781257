import React, { useState } from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GoogleMapApiKey } from '../../globalURL';
import CustomMarker from "../../images/location-pin32X32.png"
import "./map.css"
export const CommonMapComponent = (props) => {
  const { center, markersList } = props
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GoogleMapApiKey, 
  })
  const [selectedMarker, setSelectedMarker] = useState()
  const containerStyle = {
    width: '100vw',
    height: '100vh'
  };

  // const center = {
  //   lat: 16.5062,
  //   lng: 80.6480
  // };
  // const center2 = {
  //   lat: 16.1809,
  //   lng: 81.1303
  // };
  // const markersList = [
  //   {
  //     name: "Vijayawada",
  //     location: {
  //       lat: 16.5062,
  //       lng: 80.6480
  //     }
  //   },
  //   {
  //     name: "Machiipatnam",
  //     location: {
  //       lat: 16.1809,
  //       lng: 81.1303
  //     }
  //   },
  //   {
  //     name: "Pamaru",
  //     location: {
  //       lat: 16.3230,
  //       lng: 80.9612
  //     }
  //   }

  // ]
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}

    // onLoad={onLoad}
    // onUnmount={onUnmount}
    >
      {markersList.map((marker) => (
        <>
          <div key={marker.name}>
            <Marker position={marker.location} cursor='hand' options={{ icon: CustomMarker }} onClick={() => setSelectedMarker(marker)} />
          </div>
        </>

      ))}
      {selectedMarker && (
        <InfoWindow key={selectedMarker.name + "123"} position={selectedMarker.location}  >
          <> 
            <h1>{selectedMarker.search_location}</h1>
            <button onClick={() => setSelectedMarker("")} > Close </button>
          </>
        </InfoWindow>)}


    </GoogleMap>
  ) : <></>
}
