import React from 'react';

export default function TemperatureInput({ value, onChange, name, className, isReadonlyEnable}) {
  return (
    <td>
      <input
      disabled={isReadonlyEnable}
        type="number"
        pattern="[0-9]*"
        id="floatingInput"
        value={value}
        onChange={onChange}
        name={name}
        className={className}
        placeholder=""
      />
    </td>
  );
}
