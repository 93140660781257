import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../css/main.css";
import "../css/login.css";
import "../css/logincustom.css";
import "../css/structure.css";
import {
  checkEmailExistsURL,
  checkUsernameExistsURL,
  citiesListURL,
  countriesListURL,
  passwordChangeUrl,
  RegisterURL,
  statesListURL,
} from "../globalURL";
import { toast } from "react-toastify";
import Spinner from "./project/Spinner";
// import Spinner from './project/Spinner';
const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    appleation: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    username: "",
    designation: "",

    country: "",
    // state: "",
    city: "",
    email: "",
    alternative_email: "",
    mobile: "",

    user_type: "Individual",
    service_provider_name: "",
    service_provider_address: "",
    service_provider_email: "",
    service_provider_phone: "",
    service_provider_website: "",
    service_provider_country: "",
    service_provider_state: "",
    service_provider_city: "",
    password: "",
    confirm_password: "",
  });
  const [formDataErrors, setFormDataErrors] = useState({
    appleation: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    username: "",
    designation: "",

    country: "",
    // state: "",
    city: "",
    email: "",
    alternative_email: "",
    mobile: "",

    user_type: "",
    service_provider_name: "",
    service_provider_address: "",
    service_provider_email: "",
    service_provider_phone: "",
    service_provider_website: "",
    service_provider_country: "",
    service_provider_state: "",
    service_provider_city: "",
    password: "",
    confirm_password: "",
  });

  const [countiresList, setCountiresList] = useState([]);
  // const [statesList, setStatesList] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  // const [statesListCompany, setStatesListCompany] = useState("");
  // const [citiesListCompany, setCitiesListCompany] = useState("");
  const [citiesListService, setCitiesListService] = useState("");

  useEffect(() => {
    axios
      .post(countriesListURL, JSON.stringify({ status: 1 }), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setCountiresList(res["data"]["countires"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchCities = async (id) => {
    await axios
      .post(citiesListURL, JSON.stringify({ country_id: id, state_id: "" }), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCitiesList(res["data"]["data"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const fetchCities = async (stateID) => {
  //   await axios
  //     .post(
  //       citiesListURL,
  //       JSON.stringify({ state_id: stateID, country_id: "" }),
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setCitiesList(res["data"]["data"]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const fetchCheckEmailExists = async (email) => {
    if (email < 5) {
      // alert("D")
    } else {
      await axios
        .post(checkEmailExistsURL, JSON.stringify({ email: email }), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("RES:" + res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const fetchCheckUsernameExists = async (username) => {
    if (username < 3) {
      // alert("D")
    } else {
      await axios
        .post(checkUsernameExistsURL, JSON.stringify({ username: username }), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("RES:" + res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchCitiesService = async (countryID) => {
    await axios
      .post(citiesListURL, JSON.stringify({ country_id: countryID }), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCitiesListService(res["data"]["data"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = useNavigate();
  const appleationList = [
    { id: "1", name: "Mr" },
    { id: "2", name: "Ms" },
    { id: "3", name: "Mrs" },
    { id: "4", name: "Dr" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newData = { ...formData };
    let newErrors = { ...formDataErrors };

    axios
      .post(
        checkUsernameExistsURL,
        JSON.stringify({ username: formData.username }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "invalid") {
          // alert(res.data.message)

          setFormDataErrors({
            ...formDataErrors,
            ["username"]: `${res.data.message}`,
          });
        }
      })

    axios
      .post(checkEmailExistsURL, JSON.stringify({ email: formData.email }), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === "invalid") {
          // alert(res.data.message)

          setFormDataErrors({
            ...formDataErrors,
            ["email"]: `${res.data.message}`,
          });
        }
      })

    if (name === "user_type") {
      newData.user_type = value;
      if (value === "Individual") {
        newData.service_provider_name = "";
        newData.service_provider_address = "";
        newData.service_provider_email = "";
        newData.service_provider_phone = "";
        newData.service_provider_website = "";
        newData.service_provider_country = "";
        newData.service_provider_state = "";
        newData.service_provider_city = "";
      }
    } else {
      newData[name] = value;
    }

    setFormData(newData);

    // if (formDataErrors[name] && value) {
    //   setFormDataErrors({ ...formDataErrors, [name]: "" });
    // }

    // Clear the error message if the field is not empty
  if (value && newErrors[name]) {
    newErrors[name] = "";
    setFormDataErrors(newErrors);
  }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    const errors = {};

    if (!formData.appleation) {
      errors.appleation = "Please select appleation";
    }

    if (!formData.first_name) {
      errors.first_name = "Please enter first name";
    }

    if (!formData.username) {
      errors.username = "Please enter username";
    }

    if (!formData.country) {
      errors.country = "Please select country";
    }

    if (!formData.city) {
      errors.city = "Please select city";
    }

    if (!formData.email) {
      errors.email = "Please enter email";
    }

    if (!formData.mobile) {
      errors.mobile = "Please enter mobile";
    }

    if (!formData.password) {
      errors.password = "Please enter password";
    }

    if (!formData.confirm_password) {
      errors.confirm_password = "Please confirm password";
    }

    if (formData.password !== formData.confirm_password) {
      errors.password = "Both passwords must match";
    }

    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      setIsLoading(false);
      return;
      // setdisableSubmitButton(true)
    }else{
      axios
      .post(RegisterURL, JSON.stringify(formData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // alert(response?.data?.id)
        setIsLoading(false);
        // console.log("reg data...", response);
        // console.log(response["data"]["access_token"]);
        // const id = response["data"]["id"];
        if (response?.data?.id) {
          const { email } = response?.data;
          localStorage.setItem("email", email);
          const getMail = localStorage.getItem("email");
          // console.log("email", getMail);
          toast.success(response?.data?.message);
          navigate("/otp", { userid: response["data"]["id"] });
        }
      })
      .catch((error) => {
        setSubmitted(false);
        setIsLoading(false);
        toast.error("An error occurred while submitting the form");
        console.log(error);
        // console.log(error["response"]["data"]["error"]);
        // console.log(error?.response?.data?.error);
        if (error["response"]["status"] === 401) {
          if (error["response"]["data"]["error"] === "username_exists") {
            toast.error(error?.response?.data?.message);
            // setFormDataErrors({
            //   ...formDataErrors,
            //   ["username"]: "Username already exists",
            // });
            return;
          }
          if (error["response"]["data"]["error"] === "email_exists") {
            toast.error("email already exists");
            // alert("email already exists")
            setFormDataErrors({
              ...formDataErrors,
              ["email"]: "email already exists",
            });
          }
        }
      });
    }

    // if (formData.confirm_password !== formData.password) {
    //     setFormDataErrors({ ...formDataErrors, ["confirm_password"]: "Both password should match" });
    //     setdisableSubmitButton(true);
    //     return;
    // }

    // console.log(formData);
    // console.log(data);
    // Form is valid, so make the API call
  
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="login-one h-100">
            <div className="container-fluid login-one-container">
              <div className="p-30 h-100">
                <div
                  className="row main-login-one h-100 justify-content-center"
                  style={{ boxShadow: "inherit" }}
                >
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 p-0 newregisterbox">
                    <div
                      className="mb-4 mt-0"
                      style={{
                        width: "inherit",
                        height: "inherit",
                        overflow: "hidden",
                        paddingTop: "0px",
                        backgroundColor: "inherit",
                      }}
                    >
                      <div className="text-center">
                        <img src="assets/images/logo.png" alt="" height="80" />
                      </div>
                      <h6 className="mt-2 p-color text-left font-20 mt-3">
                        Register
                      </h6>
                      <form className="" action="#" onSubmit={handleSubmit}>
                        <fieldset>
                          <legend>Personal Information</legend>
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Appleation</label>
                                <span className="text-danger">*</span>
                                <select
                                  name="appleation"
                                  onChange={handleInputChange}
                                  className="form-control"
                                >
                                  <option value="" hidden>
                                    Choose Appellations
                                  </option>
                                  {appleationList &&
                                    appleationList.map((app) => (
                                      <option key={app.name} value={app.name}>
                                        {app.name}
                                      </option>
                                    ))}
                                </select>
                                {formDataErrors.appleation && (
                                  <div className="text-danger">
                                    {formDataErrors.appleation}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">First Name</label>
                                <span className="text-danger">*</span>
                                <input
                                  value={formData.first_name}
                                  name="first_name"
                                  onChange={handleInputChange}
                                  type="text"
                                  className="form-control"
                                  placeholder="Ex. John"
                                />
                                {formDataErrors.first_name && (
                                  <div className="text-danger">
                                    {formDataErrors.first_name}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">MiddleName</label>
                                <input
                                  value={formData.middle_name}
                                  name="middle_name"
                                  onChange={handleInputChange}
                                  type="text"
                                  className="form-control"
                                  placeholder="Ex.Frc"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Last Name</label>
                                <input
                                  value={formData.last_name}
                                  name="last_name"
                                  onChange={handleInputChange}
                                  type="text"
                                  className="form-control"
                                  placeholder="Doe"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">User Name</label>
                                <span className="text-danger">*</span>
                                <input
                                  value={formData.username}
                                  name="username"
                                  onChange={(event) => {
                                    handleInputChange(event);
                                    fetchCheckUsernameExists(
                                      event.target.value
                                    );
                                  }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Doe John"
                                />
                                {formDataErrors.username && (
                                  <div className="text-danger">
                                    {formDataErrors.username}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Designation</label>
                                <input
                                  value={formData.designation}
                                  name="designation"
                                  onChange={handleInputChange}
                                  type="text"
                                  className="form-control"
                                  placeholder="Manager"
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend>Contact Information</legend>
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Country</label>
                                <span className="text-danger">*</span>
                                <select
                                  onChange={(event) => {
                                    setFormData({
                                      ...formData,
                                      ["country"]: event.target.value,
                                    });
                                    fetchCities(event.target.value);
                                  }}
                                  className="form-control"
                                  name="country"
                                >
                                  <option value="" hidden>
                                    Select Country
                                  </option>
                                  {countiresList &&
                                    countiresList.map((app) => (
                                      <option
                                        key={app}
                                        value={app}
                                        selected={app === formData.country}
                                      >
                                        {app}
                                      </option>
                                    ))}
                                </select>
                                {formDataErrors.country && (
                                  <div className="text-danger">
                                    {formDataErrors.country}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">City </label>
                                <span className="text-danger">*</span>
                                <select
                                  onChange={handleInputChange}
                                  name="city"
                                  className="form-control"
                                >
                                  <option value="" hidden>
                                    Select City
                                  </option>
                                  {citiesList &&
                                    citiesList.map((app) => (
                                      <option
                                        key={app}
                                        value={app}
                                        selected={app === formData.city}
                                      >
                                        {app}
                                      </option>
                                    ))}
                                </select>
                                {formDataErrors.city && (
                                  <div className="text-danger">
                                    {formDataErrors.city}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Email</label>
                                <span className="text-danger">*</span>
                                <input
                                  value={formData.email}
                                  name="email"
                                  onChange={(event) => {
                                    handleInputChange(event);
                                    fetchCheckEmailExists(event.target.value);
                                  }}
                                  type="email"
                                  className="form-control"
                                  placeholder="Email Address"
                                />
                                {formDataErrors.email && (
                                  <div className="text-danger">
                                    {formDataErrors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">
                                  Alternate Email Address
                                </label>
                                <input
                                  value={formData.alternative_email}
                                  name="alternative_email"
                                  onChange={handleInputChange}
                                  type="text"
                                  className="form-control"
                                  placeholder="Emial address"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Mobile </label>
                                <span className="text-danger">*</span>
                                <input
                                  name="mobile"
                                  value={formData.mobile}
                                  onChange={handleInputChange}
                                  type="text"
                                  className="form-control"
                                  placeholder="1234567890"
                                />
                                {formDataErrors.mobile && (
                                  <div className="text-danger">
                                    {formDataErrors.mobile}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend>Service Provider Information </legend>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-lg-2">
                                    <div className="radiocust">
                                      <label>
                                        <input
                                          value="Individual"
                                          type="radio"
                                          onChange={handleInputChange}
                                          name="user_type"
                                          checked={
                                            formData.user_type === "Individual"
                                          }
                                        />
                                        <span></span>&nbsp;&nbsp; Individual
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="radiocust">
                                      <label>
                                        {/* onChange={(event) => setserviceProviderType(event.target.value)}  */}
                                        <input
                                          value="Company"
                                          type="radio"
                                          onChange={handleInputChange}
                                          name="user_type"
                                          checked={
                                            formData.user_type === "Company"
                                          }
                                        />
                                        <span></span>&nbsp;&nbsp; Company
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-12"
                              style={{
                                display:
                                  formData.user_type === "Company"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="form-group">
                                    <label htmlFor="">Company Name</label>
                                    <input
                                      value={formData.service_provider_name}
                                      name="service_provider_name"
                                      onChange={handleInputChange}
                                      type="text"
                                      className="form-control"
                                      placeholder="John Doe"
                                    />
                                    {formDataErrors.service_provider_name && (
                                      <div className="text-danger">
                                        {formDataErrors.service_provider_name}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="form-group">
                                    <label htmlFor="">Company Adress</label>
                                    <textarea
                                      onChange={handleInputChange}
                                      name="service_provider_address"
                                      rows="3"
                                      className="form-control"
                                      placeholder="Dubai"
                                      defaultValue={
                                        formData.service_provider_address
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="">Email</label>
                                    <input
                                      value={formData.service_provider_email}
                                      name="service_provider_email"
                                      onChange={handleInputChange}
                                      type="text"
                                      className="form-control"
                                      placeholder="Email address"
                                    />
                                    {formDataErrors.service_provider_email && (
                                      <div className="text-danger">
                                        {formDataErrors.service_provider_email}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="">Phone</label>
                                    <input
                                      value={formData.service_provider_phone}
                                      name="service_provider_phone"
                                      onChange={handleInputChange}
                                      type="text"
                                      className="form-control"
                                      placeholder="1234567890"
                                    />
                                    {formDataErrors.service_provider_phone && (
                                      <div className="text-danger">
                                        {formDataErrors.service_provider_phone}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="">Website</label>
                                    <input
                                      value={formData.service_provider_website}
                                      name="service_provider_website"
                                      onChange={handleInputChange}
                                      type="text"
                                      className="form-control"
                                      placeholder="http://mywebsite.com"
                                    />
                                    {formDataErrors.service_provider_website && (
                                      <div className="text-danger">
                                        {
                                          formDataErrors.service_provider_website
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="">
                                      Country of Incorporation{" "}
                                    </label>
                                    <select
                                      onChange={(event) => {
                                        setFormData({
                                          ...formData,
                                          ["service_provider_state"]:
                                            event.target.value,
                                        });
                                        fetchCitiesService(event.target.value);
                                      }}
                                      className="form-control"
                                      name="service_provider_state"
                                      value={formData.service_provider_state}
                                    >
                                      <option value="" hidden>
                                        Select Country
                                      </option>
                                      {countiresList &&
                                        countiresList.map((app) => (
                                          <option
                                            key={app}
                                            value={app}
                                            selected={
                                              app ===
                                              formData.service_provider_state
                                            }
                                          >
                                            {app}
                                          </option>
                                        ))}
                                    </select>

                                    {formDataErrors.service_provider_country && (
                                      <div className="text-danger">
                                        {
                                          formDataErrors.service_provider_country
                                        }
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="">City </label>

                                    <select
                                      name="service_provider_city"
                                      onChange={(event) => {
                                        setFormData({
                                          ...formData,
                                          ["service_provider_city"]:
                                            event.target.value,
                                        });
                                      }}
                                      className="form-control"
                                      value={formData.service_provider_city}
                                    >
                                      <option value="" hidden>
                                        Select City
                                      </option>

                                      {citiesListService &&
                                        citiesListService.map((app) => (
                                          <option
                                            key={app}
                                            value={app}
                                            selected={
                                              formData.service_provider_city ===
                                              (app ||
                                                formData.service_provider_city)
                                            }
                                          >
                                            {app}
                                          </option>
                                        ))}
                                    </select>
                                    {formDataErrors.service_provider_city && (
                                      <div className="text-danger">
                                        {formDataErrors.service_provider_city}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>

                        <fieldset>
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Password </label>
                                <span className="text-danger">*</span>
                                <input
                                  value={formData.password}
                                  name="password"
                                  onChange={handleInputChange}
                                  type="password"
                                  className="form-control"
                                  placeholder="****"
                                />
                                <small>6 characters minimum</small>
                                {formDataErrors.password && (
                                  <div className="text-danger">
                                    {formDataErrors.password}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Password confirmation </label>
                                <span className="text-danger">*</span>
                                <input
                                  value={formData.confirm_password}
                                  name="confirm_password"
                                  onChange={handleInputChange}
                                  type="password"
                                  className="form-control"
                                  placeholder="***"
                                />
                                {formDataErrors.confirm_password && (
                                  <div className="text-danger">
                                    {formDataErrors.confirm_password}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <button
                                type="reset"
                                className="btn btn-outline-primary mr-2"
                              >
                                Reset
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary mr-4"
                              >
                                Submit
                              </button>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 txt-right">
                              <Link
                                to="/"
                                relative="path"
                                className="text-black"
                              >
                                <b> Back to Login</b>
                              </Link>
                              <br />
                              <Link
                                to="/"
                                relative="path"
                                className="text-black"
                              >
                                <b>Didn't receive confirmation instructions?</b>
                              </Link>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                      {/* 
                      <pre style={{ color: "#fff" }}>
                        {JSON.stringify(formData, null, 2)}
                      </pre> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Register;
