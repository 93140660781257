import React from "react";
import InputWidget from "./widgets/InputWidget";

export default function OperationalPhaseDetails({ formData }) {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <h3
            style={{ fontSize: "20px" }}
            className="yellowcol text-center p-2 my-2"
          >
            Operational Phase Details
          </h3>
        </div>
        <div className="col-lg-12">
          <h3
            style={{ fontSize: "18px" }}
            className="yellowcol bg-success text-center p-2"
          >
            Chillers Information
          </h3>
          <div className="row">
            <InputWidget
              cls="col-md-6"
              readonly={true}
              value={formData.no_of_chillers_op}
              type={"text"}
              label="No Of Chillers "
            />
            <InputWidget
              cls="col-md-6"
              readonly={true}
              value={formData.operating_hours_per_year_op}
              type={"text"}
              label="Operating Hours Per Year "
            />
            {formData.no_of_chillers_op >= 1 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_1_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 1 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 2 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_2_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 2 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 3 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_3_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 3 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 4 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_4_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 4 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 5 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_5_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 5 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 6 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_6_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 6 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 7 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_7_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 7 "
                    />
                  </>
                )}
                
                 {formData.no_of_chillers_op >= 8 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_8_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 8 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 9 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_9_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 9 "
                    />
                  </>
                )}
                
                {formData.no_of_chillers_op >= 10 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.chiller_10_capacity_op}
                      type={"text"}
                      label="Capacity Of Chiller 10 "
                    />
                  </>
                )}
          </div> 
          <div className="row">
            <InputWidget
              cls="col-md-6"
              readonly={true}
              value={formData.nominal_cop_op}
              type={"text"}
              // label="Nominal COP Op (Kw/kw)"
              label={`Nominal COP Op ${formData?.selected_units}`}
            />
              <InputWidget
              cls="col-md-6"
              readonly={true}
              value={formData.minimum_start_up_capacity_op}
              type={"text"}
              // label="Minimum Startup Capacity Op(Kw/kw)"
              label={`Minimum Startup Capacity Op ${formData?.selected_units}`}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <h3
            style={{ fontSize: "18px" }}
            className="yellowcol bg-success text-center p-2"
          >
            TRHs Information
          </h3>
          <div className="row">
            <InputWidget
              cls="col-md-6"
              readonly={true}
              value={formData.no_of_trhs_op}
              type={"text"}
              label="No Of TRHs"
            />
            <div className="col-md-6"></div>
            {formData.no_of_trhs_op >= 1 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_op}
                      type={"text"}
                      label="Capacity Of TRHs 1"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 2 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_2_op}
                      type={"text"}
                      label="Capacity Of TRHs 2"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 3 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_3_op}
                      type={"text"}
                      label="Capacity Of TRHs 3"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 4 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_4_op}
                      type={"text"}
                      label="Capacity Of TRHs 4"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 5 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_5_op}
                      type={"text"}
                      label="Capacity Of TRHs 5"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 6 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_6_op}
                      type={"text"}
                      label="Capacity Of TRHs 6"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 7 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_7_op}
                      type={"text"}
                      label="Capacity Of TRHs 7"
                    />
                  </>
                )}
                
                 {formData.no_of_trhs_op >= 8 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_8_op}
                      type={"text"}
                      label="Capacity Of TRHs 8"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 9 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_9_op}
                      type={"text"}
                      label="Capacity Of TRHs 9"
                    />
                  </>
                )}
                
                {formData.no_of_trhs_op >= 10 && (
                  <>
                    <InputWidget
                      cls="col-lg-3"
                      readonly={true}
                      value={formData.capacity_of_trhs_10_op}
                      type={"text"}
                      label="Capacity Of TRHs 10"
                    />
                  </>
                )}
          </div>
        </div>


        <div className="col-lg-12">
          <h3
            style={{ fontSize: "18px" }}
            className="yellowcol bg-success text-center p-2"
          >
            Thermal Store Information
          </h3>
          <div className="row">
            <InputWidget
              cls="col-md-6"
              readonly={true}
              value={formData.is_thermal_store_available_op}
              type={"text"}
              label="Is Thermal Store Available Op?"
            />{" "}
            <InputWidget
              cls="col-md-6"
              readonly={true}
              value={formData.thermal_store_capacity_op}
              type={"text"}
              label="Thermal Store Capacity "
            />
          </div>
        </div>
        <div className="col-lg-12">
          <h3
            style={{ fontSize: "18px" }}
            className="yellowcol bg-success text-center p-2"
          >
            Pumps Information
          </h3>

          <div className="row">
            <div className="col-lg-6">
              {formData.no_of_primary_pumps_op >= 1 && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Primary Pumps
                </h3>
              )}

              <div className="row">
                {formData.no_of_primary_pumps_op &&
                  formData.primary_pump_type_op && (
                    <>
                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.primary_pump_type_op}
                        type={"text"}
                        label="Primary Pump Type "
                      />

                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.no_of_primary_pumps_op}
                        type={"text"}
                        label="No Of Primary Pumps "
                      />
                    </>
                  )}

                {formData.no_of_primary_pumps_op >= 1 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_op}
                      type={"text"}
                      label="Capacity Of Primary "
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 2 && (
                  <>
                    <InputWidget
                      cls="col-md-6"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_2_op}
                      type={"text"}
                      label="Capacity Of Primary pump 2"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 3 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_3_op}
                      type={"text"}
                      label="Capacity Of Primary pump 3"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 4 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_4_op}
                      type={"text"}
                      label="Capacity Of Primary pump 4"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 5 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_5_op}
                      type={"text"}
                      label="Capacity Of Primary pump 5"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 6 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_6_op}
                      type={"text"}
                      label="Capacity Of Primary pump 6"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 7 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_7_op}
                      type={"text"}
                      label="Capacity Of Primary pump 7"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 8 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_8_op}
                      type={"text"}
                      label="Capacity Of Primary pump 8"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 9 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_9_op}
                      type={"text"}
                      label="Capacity Of Primary pump 9"
                    />
                  </>
                )}
                {formData.no_of_primary_pumps_op >= 10 && (
                  <>
                    <InputWidget
                      cls="col-md-4"
                      readonly={true}
                      value={formData.capacity_of_primary_pumps_10_op}
                      type={"text"}
                      label="Capacity Of Primary pump 10"
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              {formData.no_of_secondary_pumps_op && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Secondary Pumps
                </h3>
              )}

              <div className="row">
                {formData.no_of_secondary_pumps_op &&
                  formData.secondary_pump_type_op && (
                    <>
                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.secondary_pump_type_op}
                        type={"text"}
                        label="Secondary Pump Type "
                      />

                      <InputWidget
                        cls="col-md-6"
                        readonly={true}
                        value={formData.no_of_secondary_pumps_op}
                        type={"text"}
                        label="No Of Secondary Pumps "
                      />
                    </>
                  )}

                {formData.no_of_secondary_pumps_op >= 1 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_op}
                      type={"text"}
                      label="Capacity Of Secondary Pump"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 2 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_2_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 2"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 3 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_3_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 3"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 4 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_4_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 4"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 5 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_5_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 5"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 6 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_6_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 6"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 7 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_7_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 7"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 8 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_8_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 8"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 9 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_9_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 9"
                    />
                  </>
                )}
                {formData.no_of_secondary_pumps_op >= 10 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_secondary_pumps_10_op}
                      type={"text"}
                      label="Capacity Of Secondary pump 10"
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              {formData.no_of_condenser_pumps_op && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Condenser Pumps
                </h3>
              )}
              <div className="row">
                {formData.no_of_condenser_pumps_op &&
                  formData.condenser_pump_type_op && (
                    <>
                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.condenser_pump_type_op}
                        type={"text"}
                        label="Condenser Pump Type "
                      />

                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.no_of_condenser_pumps_op}
                        type={"text"}
                        label="No Of Condenser Pumps "
                      />
                    </>
                  )}



{formData.no_of_condenser_pumps_op >= 1 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_op}
                    type={"text"}
                    label="Capacity Of Condenser pump"
                  />
                </>
              )}
              {formData.no_of_condenser_pumps_op >= 2 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_2_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 2"
                  />
                </>
              )}

              {formData.no_of_condenser_pumps_op >= 3 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_3_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 3"
                  />
                </>
              )}

              {formData.no_of_condenser_pumps_op >= 4 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_4_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 4"
                  />
                </>
              )}
              {formData.no_of_condenser_pumps_op >= 5 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_5_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 5"
                  />
                </>
              )}
              {formData.no_of_condenser_pumps_op >= 6 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_6_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 6"
                  />
                </>
              )}
              {formData.no_of_condenser_pumps_op >= 7 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_7_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 7"
                  />
                </>
              )}
              {formData.no_of_condenser_pumps_op >= 8 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_8_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 8"
                  />
                </>
              )}
              {formData.no_of_condenser_pumps_op >= 9 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_9_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 9"
                  />
                </>
              )}
              {formData.no_of_condenser_pumps_op >= 10 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_condenser_pumps_10_op}
                    type={"text"}
                    label="Capacity Of Condenser pump 10"
                  />
                </>
              )}
              </div>
            </div>

            <div className="col-lg-6">
              {formData.no_of_tertiary_pumps_op > 0 && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Tertiary Pumps
                </h3>
              )}

              <div className="row">
                {formData.no_of_tertiary_pumps_op &&
                  formData.tertiary_pump_type_op && (
                    <>
                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.tertiary_pump_type_op}
                        type={"text"}
                        label="Tertiary Pump Type "
                      />

                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.no_of_tertiary_pumps_op}
                        type={"text"}
                        label="No Of Tertiary Pumps "
                      />
                    </>
                  )}



{formData.no_of_tertiary_pumps_op >= 1 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 2 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_2_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 2"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 3 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_3_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 3"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 4 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_4_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 4"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 5 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_5_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 5"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 6 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_6_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 6"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 7 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_7_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 7"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 8 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_8_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 8"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 9 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_9_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 9"
                  />
                </>
              )}
              {formData.no_of_tertiary_pumps_op >= 10 && (
                <>
                  <InputWidget
                    cls="col-lg-6"
                    readonly={true}
                    value={formData.capacity_of_tertiary_pumps_10_op}
                    type={"text"}
                    label="Capacity Of Tertiary Pump 10"
                  />
                </>
              )}
              </div>

             
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              {formData.no_of_cooling_towers_op > 0 && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Cooling Tower Information
                </h3>
              )}

              <div className="row">
                {formData.no_of_cooling_towers_op &&
                  formData.cooling_tower_type_op && (
                    <>
                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.cooling_tower_type_op}
                        type={"text"}
                        label="Cooling Tower Type "
                      />

                      <InputWidget
                        cls="col-lg-6"
                        readonly={true}
                        value={formData.no_of_cooling_towers_op}
                        type={"text"}
                        label="No Of Cooling Towers "
                      />
                    </>
                  )}

                {formData.no_of_cooling_towers_op >= 1 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 1"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 2 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_2_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 2"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 3 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_3_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 3"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 4 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_4_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 4"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 5 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_5_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 5"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 6 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_6_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 6"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 7 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_7_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 7"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 8 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_8_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 8"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 9 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_9_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 9"
                    />
                  </>
                )}
                {formData.no_of_cooling_towers_op >= 10 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.capacity_of_cooling_towers_10_op}
                      type={"text"}
                      label="Capacity Of Cooling Tower 10"
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              {formData.is_renewal_energy_applicable_op === "Yes" && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Renewable Energy Information
                </h3>
              )}
              <div className="row">
                {formData.is_renewal_energy_applicable_op === "Yes" && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.is_renewal_energy_applicable_op}
                      type={"text"}
                      label="Is Renewal Energy Applicable?"
                    />
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.is_renewal_energy_generated_op}
                      type={"text"}
                      label="Renewal Energy Generated"
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              {formData.no_of_yearly_power_consumption_op > 0 && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Yearly Power Consumption
                </h3>
              )}

              <div className="row">
                {formData.no_of_yearly_power_consumption_op >= 1 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.no_of_yearly_power_consumption_op}
                      type={"text"}
                      label="No Of Yearly Power Consumption "
                    />
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_op}
                      type={"text"}
                      label="Power Consumption Per Years "
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 2 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_2_op}
                      type={"text"}
                      label="Power Consumption Per Years 2"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 3 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_3_op}
                      type={"text"}
                      label="Power Consumption Per Years 3"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 4 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_4_op}
                      type={"text"}
                      label="Power Consumption Per Years 4"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 5 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_5_op}
                      type={"text"}
                      label="Power Consumption Per Years 5"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 6 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_6_op}
                      type={"text"}
                      label="Power Consumption Per Years 6"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 7 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_7_op}
                      type={"text"}
                      label="Power Consumption Per Years 7"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 8 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_8_op}
                      type={"text"}
                      label="Power Consumption Per Years 8"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 9 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_9_op}
                      type={"text"}
                      label="Power Consumption Per Years 9"
                    />
                  </>
                )}
                {formData.no_of_yearly_power_consumption_op >= 10 && (
                  <>
                    <InputWidget
                      cls="col-lg-6"
                      readonly={true}
                      value={formData.power_consumption_per_years_10_op}
                      type={"text"}
                      label="Power Consumption Per Years 10"
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              {formData.is_submeter_available_op === "Yes" && (
                <h3
                  style={{ fontSize: "18px" }}
                  className="yellowcol text-center p-2"
                >
                  Sub-metering Information
                </h3>
              )}
              <div className="row">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <InputWidget
                      cls="col-lg-12"
                      readonly={true}
                      value={formData.is_submeter_available_op}
                      type={"text"}
                      label="Is Submeter Available "
                    />
                  </>
                )}
              </div>

              <div className="row d-flex align-items-cente">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Primary Pump
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_primary_pump_power_input}
                      type={"text"}
                      label="Sub Meter Primary Pump Power Input"
                    />
                  </>
                )}
              </div>
              <div className="row d-flex align-items-cente">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Secondary Pump
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_secondary_pump_power_input}
                      type={"text"}
                      label="Sub Meter Secondary Pump Power Input"
                    />
                  </>
                )}
              </div>
              <div className="row d-flex align-items-center">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Tertiary Pump
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_tertiary_pump_power_input}
                      type={"text"}
                      label="Sub Meter Tertiary Pump Power Input"
                    />
                  </>
                )}
              </div>
              <div className="row d-flex align-items-center">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Condenser Pump
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_condenser_pump_power_input}
                      type={"text"}
                      label="Sub Meter Condenser Pump Power Input"
                    />
                  </>
                )}
              </div>
              <div className="row">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Cooling Tower
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_cooling_tower_power_input}
                      type={"text"}
                      label="Sub Meter Cooling Tower Power Input"
                    />
                  </>
                )}
              </div>
              <div className="row d-flex align-items-center">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Chiller Information
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_chiller_infomation}
                      type={"text"}
                      label="Sub Meter Chiller Information"
                    />
                  </>
                )}
              </div>
              <div className="row d-flex align-items-center">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Thermal Store Infomation
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_termal_store_infomation}
                      type={"text"}
                      label="Sub Meter Thermal Store Information"
                    />
                  </>
                )}
              </div>
              <div className="row d-flex align-items-center">
                {formData.is_submeter_available_op === "Yes" && (
                  <>
                    <div className="col-lg-5">
                      <h3
                        style={{ fontSize: "14px" }}
                        className="yellowcol text-left p-2"
                      >
                        Renewable energy information
                      </h3>
                    </div>

                    <InputWidget
                      cls="col-lg-7"
                      readonly={true}
                      value={formData.sub_meter_renewal_energy_infomation}
                      type={"text"}
                      label="Sub Meter Renewal Energy Infomation"
                    />
                  </>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
