import React from "react";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <div
      style={{fontSize: "18px", background: "rgb(6,24, 92)"}}
      className="d-flex justify-content-center align-items-center flex-column min-vh-100 "
    >
      <p className="text-white">
        You don't have active package. Please purchase.
      </p>
      <Link to="/dashboard" className="btn btn-primary mt-2">
        Back To Home
      </Link>
    </div>
  );
}
