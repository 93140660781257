import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Auxiliaries from "./Auxiliaries";
import Dashboardsidebar from "./Dashboardsidebar";
import axios from "axios";
import {
  pumpsAndOthersUrl,
  projectDetails,
  uploadFileOrImage,
  BaseURL,
} from "../../globalURL";
import Spinner from "./Spinner";
import { CondenserContext } from "../../App";
import { toast } from "react-toastify";
import "../../css/background_image.css";

import FileUpload from "../dashboard_pages/otherdocumentfile";

const Pumps = () => {
  const { isCondenserTableDisabled } = useContext(CondenserContext);
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showAuxiliaries, setShowAuxiliaries] = useState(false);
  const [showPumps, setShowPumps] = useState(false);

  const handleAuxiliaries = () => {
    setShowAuxiliaries(true);
    setShowPumps(false);
  };

  const handlePumps = () => {
    setShowPumps(true);
    setShowAuxiliaries(false);
  };

  const initialFormData = {
    condenser_pump_type: "",
    condenser_pump_load_100: "",
    condenser_pump_load_75: "",
    condenser_pump_load_50: "",
    condenser_pump_load_25: "",
    condenser_pump_power_input_100: "",
    condenser_pump_power_input_75: "",
    condenser_pump_power_input_50: "",
    condenser_pump_power_input_25: "",
    pumps_other_document_file: "",
    primary_pump_type: "",
    primary_pump_dependency: "",
    primary_pump_load_100: "",
    primary_pump_load_75: "",
    primary_pump_load_50: "",
    primary_pump_load_25: "",
    primary_pump_power_input_100: "",
    primary_pump_power_input_75: "",
    primary_pump_power_input_50: "",
    primary_pump_power_input_25: "",
    secondary_pump_type: "",
    secondary_pump_dependency: "",
    secondary_pump_load_100: "",
    secondary_pump_load_75: "",
    secondary_pump_load_50: "",
    secondary_pump_load_25: "",
    secondary_pump_power_input_100: "",
    secondary_pump_power_input_75: "",
    secondary_pump_power_input_50: "",
    secondary_pump_power_input_25: "",
    tertiary_pump_type: "",
    tertiary_pump_dependency: "",
    tertiary_pump_load_100: "",
    tertiary_pump_load_75: "",
    tertiary_pump_load_50: "",
    tertiary_pump_load_25: "",
    tertiary_pump_power_input_100: "",
    tertiary_pump_power_input_75: "",
    tertiary_pump_power_input_50: "",
    tertiary_pump_power_input_25: "",
    // other_auxiliaries_kwh: "",
    _id: localStorage.getItem("project_id"),
    access_token: localStorage.getItem("access_token"),
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        pumpsAndOthersUrl,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      if (response?.data?.data?.id) {
        const { id } = response?.data?.data;
        navigate("/auxiliaries");
      } else {
        // alert(`${response.data.message}`);
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      // alert("An error occurred while submitting the form.");
      toast.error("Something error occured..!, Please try again.");
      console.log(error);
    }
  };


  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    fetch(uploadFileOrImage, {
      method: "POST",
      body: formDataImage,
    })
      .then((r) => r.json())
      .then((data) => {
        // console.log("sss",data["data"]["full_file_address"]);
        setFormData({
          ...formData,
          ["pumps_other_document_file"]: data["data"]["full_file_address"],
        });
      });

    // console.log("fileDocName", fileDocName);
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    axios
      .post(
        projectDetails,
        JSON.stringify({ project_id: localStorage.getItem("project_id") }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        let result = res["data"]["data"];
        setFormData(result);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const AuxiliariesProps = {
    formData,
    handleChange,
    handleSubmit,
    showAuxiliaries,
    showPumps,
    setShowAuxiliaries,
    setShowPumps,
    handleAuxiliaries,
    handlePumps,
    // isLoading
  };

  const PumpsData = () => {
    return (
      <>
        <div className="content-page mt-0  class-back-ground border-colo-top">
          <div className="content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="card transparent">
                    <div className="card-body pt-1">
                      {/* <div className="row">
                        <div className="col-lg-12">
                          <div className="page-title-box float-left mr-2">
                            <h4 className="page-title">
                              <Link onClick={handlePumps}>Pumps</Link>
                            </h4>
                          </div>
                          <div className="page-title-box">
                            <h4 className="page-title">
                              <Link onClick={handleAuxiliaries}>
                                Auxiliaries
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="page-title-box float-left mr-2">
                            <h4 className="page-title mb-0 pb-0 colyellow-chiller">Pumps</h4>
                          </div>
                        </div>
                      </div>
                      <form action="" onSubmit={handleSubmit} noValidate>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="page-title-box">
                                <i className="fe-arrow-right fa-lg chiler-color"></i> 
                                  <h4
                                    style={{ color: "rgb(0, 217, 255)",display:"inline-block" }}
                                    className="page-title fz-16 lablecolod"
                                  >
                                    Primary Pump
                                  </h4>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-floating mb-2">
                                  <select
                                    name="primary_pump_type"
                                    value={formData?.primary_pump_type}
                                    onChange={handleChange}
                                    id=""
                                    className="form-select w-100"
                                  >
                                    <option value="" hidden>
                                      Type
                                    </option>
                                    <option
                                      value="Fixed"
                                      selected={
                                        formData?.primary_pump_type ===
                                        ("Fixed" || formData?.primary_pump_type)
                                      }
                                    >
                                      Fixed
                                    </option>
                                    <option
                                      value="Varible"
                                      selected={
                                        formData?.primary_pump_type ===
                                        ("Varible" ||
                                          formData?.primary_pump_type)
                                      }
                                    >
                                      Varible
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-floating mb-2">
                                  <select
                                    name="primary_pump_dependency"
                                    onChange={handleChange}
                                    value={formData?.primary_pump_dependency}
                                    id=""
                                    className="form-select w-100"
                                  >
                                    <option value="" hidden>
                                      Dependency
                                    </option>
                                    <option
                                      value="Chiller"
                                      selected={
                                        formData?.primary_pump_dependency ===
                                        ("Chiller" ||
                                          formData?.primary_pump_dependency)
                                      }
                                    >
                                      Chiller
                                    </option>
                                    <option
                                      value="Cooling Load"
                                      selected={
                                        formData?.primary_pump_dependency ===
                                        ("Cooling Load" ||
                                          formData?.primary_pump_dependency)
                                      }
                                    >
                                      Cooling Load
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="table-responsive">
                                  <table className="table ">
                                    <thead className="bg-success-border-green">
                                      <tr className="head-color-table">
                                        <th>Chiller&nbsp;Load&nbsp;(%)</th>
                                        <th>Pump Load&nbsp;(%)</th>
                                        {/* <th>{`Power Input (${formData?.selected_units})`}</th> */}
                                        <th>{`Power Input (${formData?.selected_units})`}</th>

                                      </tr>
                                    </thead>
                                    <tbody className="border-blue-sky">
                                      <tr>
                                        <td>100</td>
                                        <td className="middleclas">
                                          <div className="form-floating form-color-none">
                                            <input
                                              value={
                                                formData?.primary_pump_load_100
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_load_100"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                           
                                             
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td >
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.primary_pump_power_input_100
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_power_input_100"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>75</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.primary_pump_load_75
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_load_75"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                            />
                                            <label htmlFor="floatingInput backtra">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.primary_pump_power_input_75
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_power_input_75"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>50</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.primary_pump_load_50
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_load_50"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.primary_pump_power_input_50
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_power_input_50"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>25</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.primary_pump_load_25
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_load_25"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.primary_pump_power_input_25
                                              }
                                              onChange={handleChange}
                                              name="primary_pump_power_input_25"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="page-title-box">
                                <i className="fe-arrow-right fa-lg chiler-color"></i> 
                                  <h4
                                 style={{ color: "rgb(0, 217, 255)", display:"inline-block" }}
                                 className="page-title fz-16 lablecolod"
                                  >


                                    Secondary Pump
                                  </h4>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-floating mb-2">
                                  <select
                                    name="secondary_pump_type"
                                    value={formData?.secondary_pump_type}
                                    onChange={handleChange}
                                    id=""
                                    className="form-select w-100"
                                  >
                                    <option value="" hidden>
                                      Type
                                    </option>
                                    <option
                                      value="Fixed"
                                      selected={
                                        formData?.secondary_pump_type ===
                                        ("Fixed" ||
                                          formData?.secondary_pump_type)
                                      }
                                    >
                                      Fixed
                                    </option>
                                    <option
                                      value="Variable"
                                      selected={
                                        formData?.secondary_pump_type ===
                                        ("Variable" ||
                                          formData?.secondary_pump_type)
                                      }
                                    >
                                      Variable
                                    </option>
                                  </select>
                                  {/* {formDataError.condenser_pump_type && (
                                  <div className="text-danger">
                                    {formDataError.condenser_pump_type}
                                  </div>
                                )} */}
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-floating mb-2">
                                  <select
                                    id=""
                                    name="secondary_pump_dependency"
                                    onChange={handleChange}
                                    value={formData?.secondary_pump_dependency}
                                    className="form-select w-100"
                                  >
                                    <option value="" hidden>
                                      Dependency
                                    </option>
                                    <option
                                      value="Chiller"
                                      selected={
                                        formData?.secondary_pump_dependency ===
                                        ("Chiller" ||
                                          formData?.secondary_pump_dependency)
                                      }
                                    >
                                      Chiller
                                    </option>
                                    <option
                                      value="Cooling Load"
                                      selected={
                                        formData?.secondary_pump_dependency ===
                                        ("Cooling Load" ||
                                          formData?.secondary_pump_dependency)
                                      }
                                    >
                                      Cooling Load
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="table-responsive">
                                  <table className="table ">
                                    <thead className="bg-success-border-green">
                                      <tr className="head-color-table">
                                        <th>Chiller&nbsp;Load&nbsp;(%)</th>
                                        <th>Pump Load&nbsp;(%)</th>
                                        {/* <th>{`Power Input (${formData?.selected_units})`}</th> */}
                                        <th>{`Power Input (${formData?.selected_units})`}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="border-blue-sky">
                                      <tr>
                                        <td>100</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_load_100
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_load_100"
                                              type="number"
                                              // pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                            />
                                            <label htmlFor="floatingInput backtra">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_power_input_100
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_power_input_100"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>75</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_load_75
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_load_75"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                            />
                                            <label htmlFor="floatingInput ">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_power_input_75
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_power_input_75"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>50</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_load_50
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_load_50"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_power_input_50
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_power_input_50"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>25</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_load_25
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_load_25"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.secondary_pump_power_input_25
                                              }
                                              onChange={handleChange}
                                              name="secondary_pump_power_input_25"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {isCondenserTableDisabled ? (
                            ""
                          ) : (
                            <>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="page-title-box">
                                    <i className="fe-arrow-right fa-lg chiler-color"></i> 
                                      <h4
                                       style={{ color: "rgb(0, 217, 255)", display:"inline-block" }}
                                       className="page-title fz-16 lablecolod"
                                      >
                                        Condenser Pump
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-floating mb-2">
                                      <select
                                        name="condenser_pump_type"
                                        onChange={handleChange}
                                        value={formData?.condenser_pump_type}
                                        id=""
                                        className="form-select w-100"
                                      >
                                        <option value="" hidden>
                                          Type
                                        </option>
                                        <option
                                          value="Fixed"
                                          selected={
                                            formData?.condenser_pump_type ===
                                            ("Fixed" ||
                                              formData?.condenser_pump_type)
                                          }
                                        >
                                          Fixed
                                        </option>
                                        <option
                                          value="Varible"
                                          selected={
                                            formData?.condenser_pump_type ===
                                            ("Varible" ||
                                              formData?.condenser_pump_type)
                                          }
                                        >
                                          Varible
                                        </option>
                                      </select>
                                      {/* {formDataError.condenser_pump_type && (
                                  <div className="text-danger">
                                    {formDataError.condenser_pump_type}
                                  </div>
                                )} */}
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="table-responsive">
                                      <table className="table ">
                                      <thead className="bg-success-border-green">
                                      <tr className="head-color-table">
                                            <th>Chiller&nbsp;Load&nbsp;(%)</th>
                                            <th>Pump Load&nbsp;(%)</th>
                                            {/* <th>{`Power Input (${formData?.selected_units})`}</th> */}
                                            <th>{`Power Input (${formData?.selected_units})`}</th>
                                          </tr>
                                        </thead>
                                        <tbody className="border-blue-sky">
                                          <tr>
                                            <td>100</td>
                                            <td className="middleclas">
                                              <div className="form-floating">
                                                <input
                                                  type="number"
                                                  // disabled={isDisabled}
                                                  pattern="[0-9]*"
                                                  className="form-control backtra"
                                                  id="floatingInput "
                                                  placeholder="Ex.1144"
                                                  value={
                                                    formData?.condenser_pump_load_100
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_load_100"
                                                  min={0}
                                                  max={100}
                                                  step="1"
                                                />
                                                <label htmlFor="floatingInput">
                                                  Pump Load
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="form-floating">
                                                <input
                                                  type="number"
                                                  value={
                                                    formData?.condenser_pump_power_input_100
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_power_input_100"
                                                  pattern="[0-9]*"
                                                  className="form-control backtra"
                                                  id="floatingInput"
                                                  placeholder="Ex.1144"
                                                />
                                                <label htmlFor="floatingInput">
                                                {`Power Input (${formData?.selected_units})`}  
                                                </label>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>75</td>
                                            <td className="middleclas">
                                              <div className="form-floating">
                                                <input
                                                  type="number"
                                                  value={
                                                    formData?.condenser_pump_load_75
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_load_75"
                                                  pattern="[0-9]*"
                                                  className="form-control backtra"
                                                  id="floatingInput"
                                                  placeholder="Ex.1144"
                                                  min={0}
                                                  max={100}
                                                  step="1"
                                                />
                                                <label htmlFor="floatingInput">
                                                  Pump Load
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="form-floating">
                                                <input
                                                  type="number"
                                                  pattern="[0-9]*"
                                                  value={
                                                    formData?.condenser_pump_power_input_75
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_power_input_75"
                                                  className="form-control backtra"
                                                  id="floatingInput"
                                                  placeholder="Ex.1144"
                                                />
                                                <label htmlFor="floatingInput">
                                                {`Power Input (${formData?.selected_units})`}  
                                                </label>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>50</td>
                                            <td className="middleclas">
                                              <div className="form-floating">
                                                <input
                                                  value={
                                                    formData?.condenser_pump_load_50
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_load_50"
                                                  type="number"
                                                  pattern="[0-9]*"
                                                  className="form-control backtra"
                                                  id="floatingInput"
                                                  placeholder="Ex.1144"
                                                  min={0}
                                                  max={100}
                                                  step="1"
                                                />
                                                <label htmlFor="floatingInput">
                                                  Pump Load
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="form-floating">
                                                <input
                                                  value={
                                                    formData?.condenser_pump_power_input_50
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_power_input_50"
                                                  type="number"
                                                  pattern="[0-9]*"
                                                  className="form-control backtra"
                                                  id="floatingInput"
                                                  placeholder="Ex.1144"
                                                />
                                                <label htmlFor="floatingInput">
                                                {`Power Input (${formData?.selected_units})`}  
                                                </label>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>25</td>
                                            <td className="middleclas">
                                              <div className="form-floating">
                                                <input
                                                  value={
                                                    formData?.condenser_pump_load_25
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_load_25"
                                                  type="number"
                                                  pattern="[0-9]*"
                                                  className="form-control backtra"
                                                  id="floatingInput"
                                                  placeholder="Ex.1144"
                                                  min={0}
                                                  max={100}
                                                  step="1"
                                                />
                                                <label htmlFor="floatingInput">
                                                  Pump Load
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="form-floating">
                                                <input
                                                  value={
                                                    formData?.condenser_pump_power_input_25
                                                  }
                                                  onChange={handleChange}
                                                  name="condenser_pump_power_input_25"
                                                  type="number"
                                                  pattern="[0-9]*"
                                                  className="form-control backtra"
                                                  id="floatingInput"
                                                  placeholder="Ex.1144"
                                                />
                                                <label htmlFor="floatingInput">
                                                {`Power Input (${formData?.selected_units})`}  
                                                </label>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="page-title-box">
                                <i className="fe-arrow-right fa-lg chiler-color"></i> 
                                  <h4
                                   style={{ color: "rgb(0, 217, 255)" ,display:"inline-block" }}
                                   className="page-title fz-16 lablecolod"
                                  >
                                    Tertiary Pump
                                  </h4>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-floating mb-2">
                                  <select
                                    name="tertiary_pump_type"
                                    value={formData?.tertiary_pump_type}
                                    onChange={handleChange}
                                    id=""
                                    className="form-select w-100"
                                  >
                                    <option value="" hidden>
                                      Type
                                    </option>
                                    <option
                                      value="Fixed"
                                      selected={
                                        formData?.tertiary_pump_type ===
                                        ("Fixed" ||
                                          formData?.tertiary_pump_type)
                                      }
                                    >
                                      Fixed
                                    </option>
                                    <option
                                      value="Varible"
                                      selected={
                                        formData?.tertiary_pump_type ===
                                        ("Varible" ||
                                          formData?.tertiary_pump_type)
                                      }
                                    >
                                      Varible
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-floating mb-2">
                                  <select
                                    value={formData?.tertiary_pump_dependency}
                                    name="tertiary_pump_dependency"
                                    onChange={handleChange}
                                    id=""
                                    className="form-select w-100"
                                  >
                                    <option value="" hidden>
                                      Dependency
                                    </option>
                                    <option
                                      value="Chiller"
                                      selected={
                                        formData?.tertiary_pump_dependency ===
                                        ("Chiller" ||
                                          formData?.tertiary_pump_dependency)
                                      }
                                    >
                                      Chiller
                                    </option>
                                    <option
                                      value="Cooling Load"
                                      selected={
                                        formData?.tertiary_pump_dependency ===
                                        ("Cooling Load" ||
                                          formData?.tertiary_pump_dependency)
                                      }
                                    >
                                      Cooling Load
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="table-responsive">
                                  <table className="table  ">
                                   <thead className="bg-success-border-green">
                                      <tr className="head-color-table">
                                        <th>Chiller&nbsp;Load&nbsp;(%)</th>
                                        <th>Pump Load&nbsp;(%)</th>
                                        {/* <th>{`Power Input (${formData?.selected_units})`}</th> */}
                                        <th>{`Power Input (${formData?.selected_units})`}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="border-blue-sky">
                                      <tr>
                                        <td>100</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_load_100
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_load_100"
                                              type="number"
                                              pattern="[0-9]*"
                                              min={0}
                                              max={100}
                                              step="1"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_power_input_100
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_power_input_100"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>75</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_load_75
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_load_75"
                                              type="number"
                                              pattern="[0-9]*"
                                              min={0}
                                              max={100}
                                              step="1"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_power_input_75
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_power_input_75"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>50</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_load_50
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_load_50"
                                              type="number"
                                              pattern="[0-9]*"
                                              min={0}
                                              max={100}
                                              step="1"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_power_input_50
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_power_input_50"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>25</td>
                                        <td className="middleclas">
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_load_25
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_load_25"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                              min={0}
                                              max={100}
                                              step="1"
                                            />
                                            <label htmlFor="floatingInput">
                                              Pump Load
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="form-floating">
                                            <input
                                              value={
                                                formData?.tertiary_pump_power_input_25
                                              }
                                              onChange={handleChange}
                                              name="tertiary_pump_power_input_25"
                                              type="number"
                                              pattern="[0-9]*"
                                              className="form-control backtra"
                                              id="floatingInput"
                                              placeholder="Ex.1144"
                                            />
                                            <label htmlFor="floatingInput">
                                              {`Power Input (${formData?.selected_units})`}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-lg-12 col-md-12 mb-2">
                            <FileUpload
                              formData={formData}
                              fileDocName="pumps_other_document_file"
                              setFormData={setFormData}
                            />
                          </div>
                        </div> */}
                         <div className="row">
                            <div className="col-lg-12 col-md-12 mb-2">
                              <label className="colyellow py-1">
                                <span style={{ color: 'rgb(0 220 255)' ,fontWeight:"700"}} className="pl-2 pb-2 mb-2">
                                 Document Upload
                                </span> 
                              </label>
                              <div className="form-floating mb-0 input-width-100">
                                <input
                                  type="file"
                                  className="filestyle form-control py-1 px-2 inputclass-name-pd-0"
                                  name="pumps_other_document_file"
                                  onChange={handleFileUpload}
                                />
                                {formData.pumps_other_document_file ? ( 
                                  <span>
                                    <p style={{ fontWeight: "bold", color: "yellow" }}>
                                      Previous File: &nbsp;
                                      <a 
                                        rel="noreferrer"
                                        style={{ color: "rgb(0 220 255)" }}
                                        href={`${BaseURL}${formData?.pumps_other_document_file}`}
                                        target="_blank"
                                      >
                                        Click Here
                                      </a>
                                    </p>
                                  </span>
                                ) : ""}

                              </div>
                            </div>
                          </div>
                        <div className="form-group row justify-content-end prj">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                            <button
                              type="submit"
                              onClick={() => navigate("/thermalstore")}
                              className="btn btn-pryellow mr-1 waves-effect waves-light"
                            ><i className="fas fa-angle-left"></i>  Return to previous step  
                            </button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6  text-right">
                            <button
                              type="submit"
                              className="btn btn-pryellow mr-1 waves-effect waves-light"
                            >
                              Go to next step <i className="fas fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* <pre style={{ color: "#fff" }}>
                      {JSON.stringify({...formData, ...auxiliarieFormData}, null, 2)}
                    </pre> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </>
      } */}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage="pumps" />
          {PumpsData()}
          {/* {showAuxiliaries ? <Auxiliaries handleSubmit={handleSubmit} embedded={true} {...AuxiliariesProps} /> : PumpsData()} */}
        </>
      )}
    </>
  );
};

export default Pumps;
