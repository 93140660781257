import React from 'react'
import "./visu.css"
import { useNavigate } from 'react-router-dom';
import Userdashsidebar from '../Userdashsidebar';

const VisulizationLandingPage = () => {

    const navigate = useNavigate();

    const redirectClick = (type)=>{
        
        if(type === "GSAS"){
            window.location.replace('https://visualisation.gsas.qa/');
        }else{
            navigate("/visuallanding")
        }
    }
    
    return (
        
        <div>
            <Userdashsidebar currentPage="visualisation-landing-page" />
            <div className="container d-flex justify-content-center  middleDiv">
                <div onClick={()=>redirectClick("Arcadia")} className="col p-5 text-center" style={{ backgroundImage: 'linear-gradient(to left, rgb(0 29 103), rgb(1 52 166))',height:"240px",margin:"auto",color:"white" }}>
                    <h1 className='txtWhite'> <b>Arcadia </b></h1>
                    <p className='txtWhite'> <i> Visulization Tool </i></p>
                </div>
                <div style={{width:"10px",height:"10px"}}></div>
                
                {/* <div  onClick={()=>redirectClick("GSAS")}  className="col p-5 text-center" style={{ backgroundImage: 'linear-gradient(to left, #78c350, rgb(23 98 1))',height:"240px",margin:"auto", }}>
                    <h1 className='txtWhite'> <b>GSAS</b> </h1>
                    <p  className='txtWhite'><i> Visualisation Tool</i> </p>
                </div> */}
            </div>
        </div >
    )
}

export default VisulizationLandingPage
