// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/Arcadia-Web-BG.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\r\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;\r\n    background-repeat: no-repeat;\r\n    background-size: cover !important;\r\n    background-attachment: fixed;\r\n  }", "",{"version":3,"sources":["webpack://./src/css/background_image.css"],"names":[],"mappings":"AAAA;IACI,8DAA0D;IAC1D,4BAA4B;IAC5B,iCAAiC;IACjC,4BAA4B;EAC9B","sourcesContent":["body {\r\n    background: url(\"../images/Arcadia-Web-BG.jpg\") !important;\r\n    background-repeat: no-repeat;\r\n    background-size: cover !important;\r\n    background-attachment: fixed;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
